import { ApiInventory } from '~/lib/api-inventory'

export class RecordService extends ApiInventory {
  getInventoryRecords() {
    return this.get(`/inventory/records`)
  }

  getCommodities() {
    return this.get(`/system/commodities`)
  }

  getCommoditySpecifications(type) {
    return this.get(`/inventory/commodities/specifications/${type}`)
  }

  getCommoditiesByOrganisation() {
    return this.get(`/inventory/commodities/by-organisation`)
  }

  createInventory(postModel) {
    return this.post('/inventory/records', postModel)
  }

  createPlantings(postModel) {
    return this.post('/inventory/records/plantings', postModel)
  }

  getVarieties(query) {
    return this.get(
      `/inventory/records/varieties/suggest?query=${query.query}&commodityType=${query.commodityType}&commodity=${query.commodity}`
    )
  }

  getInventoryRecord(id) {
    return this.get(`/inventory/records/${id}`)
  }

  getInventoryRecordsById(ids) {
    if (!Array.isArray(ids)) {
      throw new TypeError('id must be an array')
    }

    return this.get(`/inventory/records/byid?ids=${ids}`)
  }

  getInventoryRecordByFilters(filters = '', config = { limit: 25, offset: 0 }) {
    return this.get(`/inventory/records?${filters}&offset=${config.offset}&limit=${config.limit}`)
  }

  getInventoryRecordTimeline() {
    return this.get(`/inventory-records/v1/inventory/timeline`)
  }

  getInventoryRecordTrades() {
    return this.get('/inventory/records/trades')
  }

  getRecordById(recordId) {
    return this.get(`/inventory-records/v1/inventory/${recordId}`)
  }

  createSampleRecord({ postModel, id }) {
    return this.post(`/inventory/records/${id}/sample`, postModel)
  }

  editInventoryRecord(id, patchModel) {
    return this.patch(`/inventory/records/${id}`, patchModel)
  }

  createAdjustment(adjustment) {
    return this.post('/inventory/records/adjust', adjustment)
  }

  editAdjustmentFile(model, recordId, adjustmentId) {
    return this.put(`/inventory/records/${recordId}/adjust/${adjustmentId}/files`, model)
  }

  deleteAdjustmentFile(recordId, adjustmentId, fileId) {
    return this.delete(`/inventory/records/${recordId}/adjust/${adjustmentId}/files/${fileId}`)
  }

  deleteSample(recordId, sampleId) {
    return this.delete(`/inventory/records/${recordId}/sample/${sampleId}`)
  }
}
