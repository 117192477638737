export const InventoryAdjustments = () => import('../../src/modules/inventory/components/base/InventoryAdjustments.vue' /* webpackChunkName: "components/inventory-adjustments" */).then(c => wrapFunctional(c.default || c))
export const InventoryBreakdown = () => import('../../src/modules/inventory/components/base/InventoryBreakdown.vue' /* webpackChunkName: "components/inventory-breakdown" */).then(c => wrapFunctional(c.default || c))
export const SummaryBox = () => import('../../src/modules/inventory/components/base/SummaryBox.vue' /* webpackChunkName: "components/summary-box" */).then(c => wrapFunctional(c.default || c))
export const AccountBusiness = () => import('../../src/modules/inventory/components/manage/AccountBusiness.vue' /* webpackChunkName: "components/account-business" */).then(c => wrapFunctional(c.default || c))
export const AccountProfile = () => import('../../src/modules/inventory/components/manage/AccountProfile.vue' /* webpackChunkName: "components/account-profile" */).then(c => wrapFunctional(c.default || c))
export const Communications = () => import('../../src/modules/inventory/components/manage/Communications.vue' /* webpackChunkName: "components/communications" */).then(c => wrapFunctional(c.default || c))
export const StorageExpandTable = () => import('../../src/modules/inventory/components/manage/StorageExpandTable.vue' /* webpackChunkName: "components/storage-expand-table" */).then(c => wrapFunctional(c.default || c))
export const TermsOfUse = () => import('../../src/modules/inventory/components/manage/TermsOfUse.vue' /* webpackChunkName: "components/terms-of-use" */).then(c => wrapFunctional(c.default || c))
export const UpdateYourPassword = () => import('../../src/modules/inventory/components/manage/UpdateYourPassword.vue' /* webpackChunkName: "components/update-your-password" */).then(c => wrapFunctional(c.default || c))
export const ExistingOrganisationModalContent = () => import('../../src/modules/inventory/components/modal-content/ExistingOrganisationModalContent.vue' /* webpackChunkName: "components/existing-organisation-modal-content" */).then(c => wrapFunctional(c.default || c))
export const UpgradeStorageModuleModalContent = () => import('../../src/modules/inventory/components/modal-content/UpgradeStorageModuleModalContent.vue' /* webpackChunkName: "components/upgrade-storage-module-modal-content" */).then(c => wrapFunctional(c.default || c))
export const MovementsOverview = () => import('../../src/modules/inventory/components/movements/MovementsOverview.vue' /* webpackChunkName: "components/movements-overview" */).then(c => wrapFunctional(c.default || c))
export const StorageFutureMovementsOverview = () => import('../../src/modules/inventory/components/storage/StorageFutureMovementsOverview.vue' /* webpackChunkName: "components/storage-future-movements-overview" */).then(c => wrapFunctional(c.default || c))
export const StorageSummaryBar = () => import('../../src/modules/inventory/components/storage/StorageSummaryBar.vue' /* webpackChunkName: "components/storage-summary-bar" */).then(c => wrapFunctional(c.default || c))
export const StorageSummaryBarAccordion = () => import('../../src/modules/inventory/components/storage/StorageSummaryBarAccordion.vue' /* webpackChunkName: "components/storage-summary-bar-accordion" */).then(c => wrapFunctional(c.default || c))
export const ActionBar = () => import('../../src/modules/inventory/components/base/action-bar/ActionBar.vue' /* webpackChunkName: "components/action-bar" */).then(c => wrapFunctional(c.default || c))
export const BackButton = () => import('../../src/modules/inventory/components/base/back-button/BackButton.vue' /* webpackChunkName: "components/back-button" */).then(c => wrapFunctional(c.default || c))
export const FieldTip = () => import('../../src/modules/inventory/components/base/field-tip/FieldTip.vue' /* webpackChunkName: "components/field-tip" */).then(c => wrapFunctional(c.default || c))
export const FieldTipCard = () => import('../../src/modules/inventory/components/base/field-tip/FieldTipCard.vue' /* webpackChunkName: "components/field-tip-card" */).then(c => wrapFunctional(c.default || c))
export const FieldTipIcon = () => import('../../src/modules/inventory/components/base/field-tip/FieldTipIcon.vue' /* webpackChunkName: "components/field-tip-icon" */).then(c => wrapFunctional(c.default || c))
export const SingleBarGraph = () => import('../../src/modules/inventory/components/base/single-bar-graph/SingleBarGraph.vue' /* webpackChunkName: "components/single-bar-graph" */).then(c => wrapFunctional(c.default || c))
export const AccordionFilterToggles = () => import('../../src/modules/inventory/components/filters/accordions/AccordionFilterToggles.vue' /* webpackChunkName: "components/accordion-filter-toggles" */).then(c => wrapFunctional(c.default || c))
export const ActionsFiltersAccordion = () => import('../../src/modules/inventory/components/filters/accordions/ActionsFiltersAccordion.vue' /* webpackChunkName: "components/actions-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const LoadsFilterAccordion = () => import('../../src/modules/inventory/components/filters/accordions/LoadsFilterAccordion.vue' /* webpackChunkName: "components/loads-filter-accordion" */).then(c => wrapFunctional(c.default || c))
export const PlannedMovementsFiltersAccordion = () => import('../../src/modules/inventory/components/filters/accordions/PlannedMovementsFiltersAccordion.vue' /* webpackChunkName: "components/planned-movements-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const RecordsFiltersAccordion = () => import('../../src/modules/inventory/components/filters/accordions/RecordsFiltersAccordion.vue' /* webpackChunkName: "components/records-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const StorageContractsFiltersAccordion = () => import('../../src/modules/inventory/components/filters/accordions/StorageContractsFiltersAccordion.vue' /* webpackChunkName: "components/storage-contracts-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const StoresFiltersAccordion = () => import('../../src/modules/inventory/components/filters/accordions/StoresFiltersAccordion.vue' /* webpackChunkName: "components/stores-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const ActionsFilterHeader = () => import('../../src/modules/inventory/components/filters/headers/ActionsFilterHeader.vue' /* webpackChunkName: "components/actions-filter-header" */).then(c => wrapFunctional(c.default || c))
export const LocationFilterHeader = () => import('../../src/modules/inventory/components/filters/headers/LocationFilterHeader.vue' /* webpackChunkName: "components/location-filter-header" */).then(c => wrapFunctional(c.default || c))
export const PlannedMovementsFilterHeader = () => import('../../src/modules/inventory/components/filters/headers/PlannedMovementsFilterHeader.vue' /* webpackChunkName: "components/planned-movements-filter-header" */).then(c => wrapFunctional(c.default || c))
export const RecordFilterHeader = () => import('../../src/modules/inventory/components/filters/headers/RecordFilterHeader.vue' /* webpackChunkName: "components/record-filter-header" */).then(c => wrapFunctional(c.default || c))
export const StorageContractsFilterHeader = () => import('../../src/modules/inventory/components/filters/headers/StorageContractsFilterHeader.vue' /* webpackChunkName: "components/storage-contracts-filter-header" */).then(c => wrapFunctional(c.default || c))
export const StoresFilterHeader = () => import('../../src/modules/inventory/components/filters/headers/StoresFilterHeader.vue' /* webpackChunkName: "components/stores-filter-header" */).then(c => wrapFunctional(c.default || c))
export const FormAccountBusiness = () => import('../../src/modules/inventory/components/form/account-business/FormAccountBusiness.vue' /* webpackChunkName: "components/form-account-business" */).then(c => wrapFunctional(c.default || c))
export const AddAddress = () => import('../../src/modules/inventory/components/form/add-new-location/AddAddress.vue' /* webpackChunkName: "components/add-address" */).then(c => wrapFunctional(c.default || c))
export const FormAddress = () => import('../../src/modules/inventory/components/form/add-new-location/FormAddress.vue' /* webpackChunkName: "components/form-address" */).then(c => wrapFunctional(c.default || c))
export const PreciseLocation = () => import('../../src/modules/inventory/components/form/add-new-location/PreciseLocation.vue' /* webpackChunkName: "components/precise-location" */).then(c => wrapFunctional(c.default || c))
export const FormBusinessConnection = () => import('../../src/modules/inventory/components/form/business-connections/FormBusinessConnection.vue' /* webpackChunkName: "components/form-business-connection" */).then(c => wrapFunctional(c.default || c))
export const FormBusinessConnectionContact = () => import('../../src/modules/inventory/components/form/business-connections/FormBusinessConnectionContact.vue' /* webpackChunkName: "components/form-business-connection-contact" */).then(c => wrapFunctional(c.default || c))
export const SelectBusinessConnections = () => import('../../src/modules/inventory/components/form/business-connections/SelectBusinessConnections.vue' /* webpackChunkName: "components/select-business-connections" */).then(c => wrapFunctional(c.default || c))
export const SelectBusinessConnectionsHeader = () => import('../../src/modules/inventory/components/form/business-connections/SelectBusinessConnectionsHeader.vue' /* webpackChunkName: "components/select-business-connections-header" */).then(c => wrapFunctional(c.default || c))
export const FormFarmDetails = () => import('../../src/modules/inventory/components/form/farm-details/FormFarmDetails.vue' /* webpackChunkName: "components/form-farm-details" */).then(c => wrapFunctional(c.default || c))
export const FormPlannedMovement = () => import('../../src/modules/inventory/components/form/planned-movement/FormPlannedMovement.vue' /* webpackChunkName: "components/form-planned-movement" */).then(c => wrapFunctional(c.default || c))
export const FormEditRecord = () => import('../../src/modules/inventory/components/form/record/FormEditRecord.vue' /* webpackChunkName: "components/form-edit-record" */).then(c => wrapFunctional(c.default || c))
export const FormSampleRecord = () => import('../../src/modules/inventory/components/form/sample-record/FormSampleRecord.vue' /* webpackChunkName: "components/form-sample-record" */).then(c => wrapFunctional(c.default || c))
export const FormTeamMemberPermissions = () => import('../../src/modules/inventory/components/form/team-member-permissions copy/FormTeamMemberPermissions.vue' /* webpackChunkName: "components/form-team-member-permissions" */).then(c => wrapFunctional(c.default || c))
export const FormUpdateYourPassword = () => import('../../src/modules/inventory/components/form/update-your-password/FormUpdateYourPassword.vue' /* webpackChunkName: "components/form-update-your-password" */).then(c => wrapFunctional(c.default || c))
export const FormWeighbridgeTicket = () => import('../../src/modules/inventory/components/form/weighbridge-ticket/FormWeighbridgeTicket.vue' /* webpackChunkName: "components/form-weighbridge-ticket" */).then(c => wrapFunctional(c.default || c))
export const InventoryGuardAuth = () => import('../../src/modules/inventory/components/guards/auth/InventoryGuardAuth.vue' /* webpackChunkName: "components/inventory-guard-auth" */).then(c => wrapFunctional(c.default || c))
export const InventoryLayoutAdminShell = () => import('../../src/modules/inventory/components/layouts/admin-shell/InventoryLayoutAdminShell.vue' /* webpackChunkName: "components/inventory-layout-admin-shell" */).then(c => wrapFunctional(c.default || c))
export const InventoryLayoutAdminSidebar = () => import('../../src/modules/inventory/components/layouts/admin-sidebar/InventoryLayoutAdminSidebar.vue' /* webpackChunkName: "components/inventory-layout-admin-sidebar" */).then(c => wrapFunctional(c.default || c))
export const InventoryLayoutAuth = () => import('../../src/modules/inventory/components/layouts/auth/InventoryLayoutAuth.vue' /* webpackChunkName: "components/inventory-layout-auth" */).then(c => wrapFunctional(c.default || c))
export const ImpersonateBanner = () => import('../../src/modules/inventory/components/layouts/banners/ImpersonateBanner.vue' /* webpackChunkName: "components/impersonate-banner" */).then(c => wrapFunctional(c.default || c))
export const InventoryLayoutAuthHeader = () => import('../../src/modules/inventory/components/layouts/header/InventoryLayoutAuthHeader.vue' /* webpackChunkName: "components/inventory-layout-auth-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../src/modules/inventory/components/layouts/header/LayoutHeader.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const LocationsHeader = () => import('../../src/modules/inventory/components/manage/headers/LocationsHeader.vue' /* webpackChunkName: "components/locations-header" */).then(c => wrapFunctional(c.default || c))
export const InventoryNavItems = () => import('../../src/modules/inventory/components/nav/inventory-nav-items/InventoryNavItems.vue' /* webpackChunkName: "components/inventory-nav-items" */).then(c => wrapFunctional(c.default || c))
export const BusinessUnitSelector = () => import('../../src/modules/inventory/components/storage/business-units/BusinessUnitSelector.vue' /* webpackChunkName: "components/business-unit-selector" */).then(c => wrapFunctional(c.default || c))
export const MarketListingFiltersAccordion = () => import('../../src/modules/inventory/components/filters/accordions/trading/MarketListingFiltersAccordion.vue' /* webpackChunkName: "components/market-listing-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const TradeRequestFiltersAccordion = () => import('../../src/modules/inventory/components/filters/accordions/trading/TradeRequestFiltersAccordion.vue' /* webpackChunkName: "components/trade-request-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const FormBusinessUnit = () => import('../../src/modules/inventory/components/form/add-new-location/business-unit/FormBusinessUnit.vue' /* webpackChunkName: "components/form-business-unit" */).then(c => wrapFunctional(c.default || c))
export const FormAddEditNewField = () => import('../../src/modules/inventory/components/form/add-new-location/field/FormAddEditNewField.vue' /* webpackChunkName: "components/form-add-edit-new-field" */).then(c => wrapFunctional(c.default || c))
export const FormLocationGroup = () => import('../../src/modules/inventory/components/form/add-new-location/location-group/FormLocationGroup.vue' /* webpackChunkName: "components/form-location-group" */).then(c => wrapFunctional(c.default || c))
export const FormAddOrEditStore = () => import('../../src/modules/inventory/components/form/add-new-location/store/FormAddOrEditStore.vue' /* webpackChunkName: "components/form-add-or-edit-store" */).then(c => wrapFunctional(c.default || c))
export const FormInventoryMovementsNew = () => import('../../src/modules/inventory/components/form/inventory/movements/FormInventoryMovementsNew.vue' /* webpackChunkName: "components/form-inventory-movements-new" */).then(c => wrapFunctional(c.default || c))
export const MovementBusinessSelect = () => import('../../src/modules/inventory/components/form/inventory/movements/MovementBusinessSelect.vue' /* webpackChunkName: "components/movement-business-select" */).then(c => wrapFunctional(c.default || c))
export const MovementStorageSelect = () => import('../../src/modules/inventory/components/form/inventory/movements/MovementStorageSelect.vue' /* webpackChunkName: "components/movement-storage-select" */).then(c => wrapFunctional(c.default || c))
export const MovementSummaryPanel = () => import('../../src/modules/inventory/components/form/inventory/movements/MovementSummaryPanel.vue' /* webpackChunkName: "components/movement-summary-panel" */).then(c => wrapFunctional(c.default || c))
export const FormAddExistingInventory = () => import('../../src/modules/inventory/components/form/inventory/partial/FormAddExistingInventory.vue' /* webpackChunkName: "components/form-add-existing-inventory" */).then(c => wrapFunctional(c.default || c))
export const FormAddPlantedInventory = () => import('../../src/modules/inventory/components/form/inventory/partial/FormAddPlantedInventory.vue' /* webpackChunkName: "components/form-add-planted-inventory" */).then(c => wrapFunctional(c.default || c))
export const FormAddStorageNote = () => import('../../src/modules/inventory/components/form/inventory/partial/FormAddStorageNote.vue' /* webpackChunkName: "components/form-add-storage-note" */).then(c => wrapFunctional(c.default || c))
export const FormAddStorageNotes = () => import('../../src/modules/inventory/components/form/inventory/partial/FormAddStorageNotes.vue' /* webpackChunkName: "components/form-add-storage-notes" */).then(c => wrapFunctional(c.default || c))
export const FormBusinessSource = () => import('../../src/modules/inventory/components/form/inventory/partial/FormBusinessSource.vue' /* webpackChunkName: "components/form-business-source" */).then(c => wrapFunctional(c.default || c))
export const FormCommodityDetails = () => import('../../src/modules/inventory/components/form/inventory/partial/FormCommodityDetails.vue' /* webpackChunkName: "components/form-commodity-details" */).then(c => wrapFunctional(c.default || c))
export const FormPotentialCommodities = () => import('../../src/modules/inventory/components/form/inventory/partial/FormPotentialCommodities.vue' /* webpackChunkName: "components/form-potential-commodities" */).then(c => wrapFunctional(c.default || c))
export const FormSubstorage = () => import('../../src/modules/inventory/components/form/inventory/partial/FormSubstorage.vue' /* webpackChunkName: "components/form-substorage" */).then(c => wrapFunctional(c.default || c))
export const FormEditStorageContract = () => import('../../src/modules/inventory/components/form/inventory/storage-contracts/FormEditStorageContract.vue' /* webpackChunkName: "components/form-edit-storage-contract" */).then(c => wrapFunctional(c.default || c))
export const StorageContractsSelect = () => import('../../src/modules/inventory/components/form/inventory/storage-contracts/StorageContractsSelect.vue' /* webpackChunkName: "components/storage-contracts-select" */).then(c => wrapFunctional(c.default || c))
export const FormTradeNew = () => import('../../src/modules/inventory/components/form/trade/new/FormTradeNew.vue' /* webpackChunkName: "components/form-trade-new" */).then(c => wrapFunctional(c.default || c))
export const MovementExternalLocationStep = () => import('../../src/modules/inventory/components/form/inventory/movements/form-steps/MovementExternalLocationStep.vue' /* webpackChunkName: "components/movement-external-location-step" */).then(c => wrapFunctional(c.default || c))
export const FormContractDetails = () => import('../../src/modules/inventory/components/form/inventory/storage-contracts/partial/FormContractDetails.vue' /* webpackChunkName: "components/form-contract-details" */).then(c => wrapFunctional(c.default || c))
export const FormStorageServices = () => import('../../src/modules/inventory/components/form/inventory/storage-contracts/partial/FormStorageServices.vue' /* webpackChunkName: "components/form-storage-services" */).then(c => wrapFunctional(c.default || c))
export const FeedbackValidation = () => import('../../src/components/atoms/FeedbackValidation.vue' /* webpackChunkName: "components/feedback-validation" */).then(c => wrapFunctional(c.default || c))
export const HCarousel = () => import('../../src/components/atoms/HCarousel.vue' /* webpackChunkName: "components/h-carousel" */).then(c => wrapFunctional(c.default || c))
export const HContainer = () => import('../../src/components/atoms/HContainer.vue' /* webpackChunkName: "components/h-container" */).then(c => wrapFunctional(c.default || c))
export const HHint = () => import('../../src/components/atoms/HHint.vue' /* webpackChunkName: "components/h-hint" */).then(c => wrapFunctional(c.default || c))
export const HOpacityOverlay = () => import('../../src/components/atoms/HOpacityOverlay.vue' /* webpackChunkName: "components/h-opacity-overlay" */).then(c => wrapFunctional(c.default || c))
export const HPill = () => import('../../src/components/atoms/HPill.vue' /* webpackChunkName: "components/h-pill" */).then(c => wrapFunctional(c.default || c))
export const HTextArea = () => import('../../src/components/atoms/HTextArea.vue' /* webpackChunkName: "components/h-text-area" */).then(c => wrapFunctional(c.default || c))
export const PriceDelta = () => import('../../src/components/atoms/PriceDelta.vue' /* webpackChunkName: "components/price-delta" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../src/components/atoms/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const CardButton = () => import('../../src/components/elements/CardButton.vue' /* webpackChunkName: "components/card-button" */).then(c => wrapFunctional(c.default || c))
export const CardStatsGroup = () => import('../../src/components/elements/CardStatsGroup.vue' /* webpackChunkName: "components/card-stats-group" */).then(c => wrapFunctional(c.default || c))
export const CardStatsGroupItem = () => import('../../src/components/elements/CardStatsGroupItem.vue' /* webpackChunkName: "components/card-stats-group-item" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../src/components/elements/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const CheckboxGroup = () => import('../../src/components/elements/CheckboxGroup.vue' /* webpackChunkName: "components/checkbox-group" */).then(c => wrapFunctional(c.default || c))
export const CheckboxList = () => import('../../src/components/elements/CheckboxList.vue' /* webpackChunkName: "components/checkbox-list" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../src/components/elements/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const ContainerPage = () => import('../../src/components/elements/ContainerPage.vue' /* webpackChunkName: "components/container-page" */).then(c => wrapFunctional(c.default || c))
export const DatePickerWrapper = () => import('../../src/components/elements/DatePickerWrapper.vue' /* webpackChunkName: "components/date-picker-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Divider = () => import('../../src/components/elements/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c))
export const FormLabel = () => import('../../src/components/elements/FormLabel.vue' /* webpackChunkName: "components/form-label" */).then(c => wrapFunctional(c.default || c))
export const HText = () => import('../../src/components/elements/HText.vue' /* webpackChunkName: "components/h-text" */).then(c => wrapFunctional(c.default || c))
export const HTitle = () => import('../../src/components/elements/HTitle.vue' /* webpackChunkName: "components/h-title" */).then(c => wrapFunctional(c.default || c))
export const List = () => import('../../src/components/elements/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c))
export const FormUser = () => import('../../src/components/forms/FormUser.vue' /* webpackChunkName: "components/form-user" */).then(c => wrapFunctional(c.default || c))
export const HarvestSummary = () => import('../../src/components/inventory/HarvestSummary.vue' /* webpackChunkName: "components/harvest-summary" */).then(c => wrapFunctional(c.default || c))
export const InventoryRecordTimeline = () => import('../../src/components/inventory/InventoryRecordTimeline.vue' /* webpackChunkName: "components/inventory-record-timeline" */).then(c => wrapFunctional(c.default || c))
export const LoadsRecorded = () => import('../../src/components/inventory/LoadsRecorded.vue' /* webpackChunkName: "components/loads-recorded" */).then(c => wrapFunctional(c.default || c))
export const Timeline = () => import('../../src/components/inventory/Timeline.vue' /* webpackChunkName: "components/timeline" */).then(c => wrapFunctional(c.default || c))
export const GridContainerDualContent = () => import('../../src/components/layouts/GridContainerDualContent.vue' /* webpackChunkName: "components/grid-container-dual-content" */).then(c => wrapFunctional(c.default || c))
export const BusinessUnitTag = () => import('../../src/components/locations/BusinessUnitTag.vue' /* webpackChunkName: "components/business-unit-tag" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../src/components/molecules/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AdvancePayButton = () => import('../../src/components/molecules/AdvancePayButton.vue' /* webpackChunkName: "components/advance-pay-button" */).then(c => wrapFunctional(c.default || c))
export const AdvancePaymentBanner = () => import('../../src/components/molecules/AdvancePaymentBanner.vue' /* webpackChunkName: "components/advance-payment-banner" */).then(c => wrapFunctional(c.default || c))
export const AdvancePaymentSteps = () => import('../../src/components/molecules/AdvancePaymentSteps.vue' /* webpackChunkName: "components/advance-payment-steps" */).then(c => wrapFunctional(c.default || c))
export const CallToActionBanner = () => import('../../src/components/molecules/CallToActionBanner.vue' /* webpackChunkName: "components/call-to-action-banner" */).then(c => wrapFunctional(c.default || c))
export const CardUserSummary = () => import('../../src/components/molecules/CardUserSummary.vue' /* webpackChunkName: "components/card-user-summary" */).then(c => wrapFunctional(c.default || c))
export const ClickTile = () => import('../../src/components/molecules/ClickTile.vue' /* webpackChunkName: "components/click-tile" */).then(c => wrapFunctional(c.default || c))
export const CommoditySelectedCard = () => import('../../src/components/molecules/CommoditySelectedCard.vue' /* webpackChunkName: "components/commodity-selected-card" */).then(c => wrapFunctional(c.default || c))
export const ContractAmendment = () => import('../../src/components/molecules/ContractAmendment.vue' /* webpackChunkName: "components/contract-amendment" */).then(c => wrapFunctional(c.default || c))
export const CountdownCard = () => import('../../src/components/molecules/CountdownCard.vue' /* webpackChunkName: "components/countdown-card" */).then(c => wrapFunctional(c.default || c))
export const DateDisplay = () => import('../../src/components/molecules/DateDisplay.vue' /* webpackChunkName: "components/date-display" */).then(c => wrapFunctional(c.default || c))
export const FileUploadListItem = () => import('../../src/components/molecules/FileUploadListItem.vue' /* webpackChunkName: "components/file-upload-list-item" */).then(c => wrapFunctional(c.default || c))
export const FloatingNotifications = () => import('../../src/components/molecules/FloatingNotifications.vue' /* webpackChunkName: "components/floating-notifications" */).then(c => wrapFunctional(c.default || c))
export const FormStatusContainer = () => import('../../src/components/molecules/FormStatusContainer.vue' /* webpackChunkName: "components/form-status-container" */).then(c => wrapFunctional(c.default || c))
export const HButtonsPill = () => import('../../src/components/molecules/HButtonsPill.vue' /* webpackChunkName: "components/h-buttons-pill" */).then(c => wrapFunctional(c.default || c))
export const HCard = () => import('../../src/components/molecules/HCard.vue' /* webpackChunkName: "components/h-card" */).then(c => wrapFunctional(c.default || c))
export const HDialog = () => import('../../src/components/molecules/HDialog.vue' /* webpackChunkName: "components/h-dialog" */).then(c => wrapFunctional(c.default || c))
export const HNotification = () => import('../../src/components/molecules/HNotification.vue' /* webpackChunkName: "components/h-notification" */).then(c => wrapFunctional(c.default || c))
export const HNotificationBar = () => import('../../src/components/molecules/HNotificationBar.vue' /* webpackChunkName: "components/h-notification-bar" */).then(c => wrapFunctional(c.default || c))
export const HRadio = () => import('../../src/components/molecules/HRadio.vue' /* webpackChunkName: "components/h-radio" */).then(c => wrapFunctional(c.default || c))
export const HToggle = () => import('../../src/components/molecules/HToggle.vue' /* webpackChunkName: "components/h-toggle" */).then(c => wrapFunctional(c.default || c))
export const HarvestPlanHowToDialogContent = () => import('../../src/components/molecules/HarvestPlanHowToDialogContent.vue' /* webpackChunkName: "components/harvest-plan-how-to-dialog-content" */).then(c => wrapFunctional(c.default || c))
export const HarvestPlansHowToDialogContent = () => import('../../src/components/molecules/HarvestPlansHowToDialogContent.vue' /* webpackChunkName: "components/harvest-plans-how-to-dialog-content" */).then(c => wrapFunctional(c.default || c))
export const HowToStepsAdvancePay = () => import('../../src/components/molecules/HowToStepsAdvancePay.vue' /* webpackChunkName: "components/how-to-steps-advance-pay" */).then(c => wrapFunctional(c.default || c))
export const ListingCard = () => import('../../src/components/molecules/ListingCard.vue' /* webpackChunkName: "components/listing-card" */).then(c => wrapFunctional(c.default || c))
export const ListingRecipientsPanel = () => import('../../src/components/molecules/ListingRecipientsPanel.vue' /* webpackChunkName: "components/listing-recipients-panel" */).then(c => wrapFunctional(c.default || c))
export const MarketComparison = () => import('../../src/components/molecules/MarketComparison.vue' /* webpackChunkName: "components/market-comparison" */).then(c => wrapFunctional(c.default || c))
export const MarketListingHowToDialogContent = () => import('../../src/components/molecules/MarketListingHowToDialogContent.vue' /* webpackChunkName: "components/market-listing-how-to-dialog-content" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirm = () => import('../../src/components/molecules/ModalConfirm.vue' /* webpackChunkName: "components/modal-confirm" */).then(c => wrapFunctional(c.default || c))
export const MonthRangeSelector = () => import('../../src/components/molecules/MonthRangeSelector.vue' /* webpackChunkName: "components/month-range-selector" */).then(c => wrapFunctional(c.default || c))
export const NavAccordion = () => import('../../src/components/molecules/NavAccordion.vue' /* webpackChunkName: "components/nav-accordion" */).then(c => wrapFunctional(c.default || c))
export const NumberedListItem = () => import('../../src/components/molecules/NumberedListItem.vue' /* webpackChunkName: "components/numbered-list-item" */).then(c => wrapFunctional(c.default || c))
export const PasswordRequirements = () => import('../../src/components/molecules/PasswordRequirements.vue' /* webpackChunkName: "components/password-requirements" */).then(c => wrapFunctional(c.default || c))
export const PriceCard = () => import('../../src/components/molecules/PriceCard.vue' /* webpackChunkName: "components/price-card" */).then(c => wrapFunctional(c.default || c))
export const PriceOffersSummary = () => import('../../src/components/molecules/PriceOffersSummary.vue' /* webpackChunkName: "components/price-offers-summary" */).then(c => wrapFunctional(c.default || c))
export const PricePrediction = () => import('../../src/components/molecules/PricePrediction.vue' /* webpackChunkName: "components/price-prediction" */).then(c => wrapFunctional(c.default || c))
export const RadioButtonGroup = () => import('../../src/components/molecules/RadioButtonGroup.vue' /* webpackChunkName: "components/radio-button-group" */).then(c => wrapFunctional(c.default || c))
export const Result = () => import('../../src/components/molecules/Result.vue' /* webpackChunkName: "components/result" */).then(c => wrapFunctional(c.default || c))
export const BidSummary = () => import('../../src/components/trading/BidSummary.vue' /* webpackChunkName: "components/bid-summary" */).then(c => wrapFunctional(c.default || c))
export const MyPosition = () => import('../../src/components/trading/MyPosition.vue' /* webpackChunkName: "components/my-position" */).then(c => wrapFunctional(c.default || c))
export const PositionCalculator = () => import('../../src/components/trading/PositionCalculator.vue' /* webpackChunkName: "components/position-calculator" */).then(c => wrapFunctional(c.default || c))
export const SalesContractSummary = () => import('../../src/components/trading/SalesContractSummary.vue' /* webpackChunkName: "components/sales-contract-summary" */).then(c => wrapFunctional(c.default || c))
export const SalesContractTimeline = () => import('../../src/components/trading/SalesContractTimeline.vue' /* webpackChunkName: "components/sales-contract-timeline" */).then(c => wrapFunctional(c.default || c))
export const SalesContracts = () => import('../../src/components/trading/SalesContracts.vue' /* webpackChunkName: "components/sales-contracts" */).then(c => wrapFunctional(c.default || c))
export const SpecificationTable = () => import('../../src/components/trading/SpecificationTable.vue' /* webpackChunkName: "components/specification-table" */).then(c => wrapFunctional(c.default || c))
export const StorageContracts = () => import('../../src/components/trading/StorageContracts.vue' /* webpackChunkName: "components/storage-contracts" */).then(c => wrapFunctional(c.default || c))
export const Traded = () => import('../../src/components/trading/Traded.vue' /* webpackChunkName: "components/traded" */).then(c => wrapFunctional(c.default || c))
export const AdvancePaySummary = () => import('../../src/components/organisms/AdvancePaySummary.vue' /* webpackChunkName: "components/advance-pay-summary" */).then(c => wrapFunctional(c.default || c))
export const AssuranceCertificateMiniOld = () => import('../../src/components/organisms/AssuranceCertificateMiniOld.vue' /* webpackChunkName: "components/assurance-certificate-mini-old" */).then(c => wrapFunctional(c.default || c))
export const BidAlertsPanel = () => import('../../src/components/organisms/BidAlertsPanel.vue' /* webpackChunkName: "components/bid-alerts-panel" */).then(c => wrapFunctional(c.default || c))
export const BidPriceOffer = () => import('../../src/components/organisms/BidPriceOffer.vue' /* webpackChunkName: "components/bid-price-offer" */).then(c => wrapFunctional(c.default || c))
export const BulkPlantingForm = () => import('../../src/components/organisms/BulkPlantingForm.vue' /* webpackChunkName: "components/bulk-planting-form" */).then(c => wrapFunctional(c.default || c))
export const BuyerListingNextStepsPanel = () => import('../../src/components/organisms/BuyerListingNextStepsPanel.vue' /* webpackChunkName: "components/buyer-listing-next-steps-panel" */).then(c => wrapFunctional(c.default || c))
export const ContactCustomerSupportPanel = () => import('../../src/components/organisms/ContactCustomerSupportPanel.vue' /* webpackChunkName: "components/contact-customer-support-panel" */).then(c => wrapFunctional(c.default || c))
export const FileUpload = () => import('../../src/components/organisms/FileUpload.vue' /* webpackChunkName: "components/file-upload" */).then(c => wrapFunctional(c.default || c))
export const FileUploadStatusContainer = () => import('../../src/components/organisms/FileUploadStatusContainer.vue' /* webpackChunkName: "components/file-upload-status-container" */).then(c => wrapFunctional(c.default || c))
export const FormAdvancePayBankDetails = () => import('../../src/components/organisms/FormAdvancePayBankDetails.vue' /* webpackChunkName: "components/form-advance-pay-bank-details" */).then(c => wrapFunctional(c.default || c))
export const FormCommoditySelect = () => import('../../src/components/organisms/FormCommoditySelect.vue' /* webpackChunkName: "components/form-commodity-select" */).then(c => wrapFunctional(c.default || c))
export const FormFixPriceSalesContract = () => import('../../src/components/organisms/FormFixPriceSalesContract.vue' /* webpackChunkName: "components/form-fix-price-sales-contract" */).then(c => wrapFunctional(c.default || c))
export const FormMovementAndQuantity = () => import('../../src/components/organisms/FormMovementAndQuantity.vue' /* webpackChunkName: "components/form-movement-and-quantity" */).then(c => wrapFunctional(c.default || c))
export const FormSpreadSheetRowControls = () => import('../../src/components/organisms/FormSpreadSheetRowControls.vue' /* webpackChunkName: "components/form-spread-sheet-row-controls" */).then(c => wrapFunctional(c.default || c))
export const GraindexWelcomeMessage = () => import('../../src/components/organisms/GraindexWelcomeMessage.vue' /* webpackChunkName: "components/graindex-welcome-message" */).then(c => wrapFunctional(c.default || c))
export const ListingStatus = () => import('../../src/components/organisms/ListingStatus.vue' /* webpackChunkName: "components/listing-status" */).then(c => wrapFunctional(c.default || c))
export const MarketListingPriceSummary = () => import('../../src/components/organisms/MarketListingPriceSummary.vue' /* webpackChunkName: "components/market-listing-price-summary" */).then(c => wrapFunctional(c.default || c))
export const ModalAcceptReopenPrice = () => import('../../src/components/organisms/ModalAcceptReopenPrice.vue' /* webpackChunkName: "components/modal-accept-reopen-price" */).then(c => wrapFunctional(c.default || c))
export const ModalAdvancePayHowItWorks = () => import('../../src/components/organisms/ModalAdvancePayHowItWorks.vue' /* webpackChunkName: "components/modal-advance-pay-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const ModalAdvancePaymentExampleCalculator = () => import('../../src/components/organisms/ModalAdvancePaymentExampleCalculator.vue' /* webpackChunkName: "components/modal-advance-payment-example-calculator" */).then(c => wrapFunctional(c.default || c))
export const ModalBidAddContractInfo = () => import('../../src/components/organisms/ModalBidAddContractInfo.vue' /* webpackChunkName: "components/modal-bid-add-contract-info" */).then(c => wrapFunctional(c.default || c))
export const ModalFixContract = () => import('../../src/components/organisms/ModalFixContract.vue' /* webpackChunkName: "components/modal-fix-contract" */).then(c => wrapFunctional(c.default || c))
export const ModalListingActivePriceReceived = () => import('../../src/components/organisms/ModalListingActivePriceReceived.vue' /* webpackChunkName: "components/modal-listing-active-price-received" */).then(c => wrapFunctional(c.default || c))
export const ModalMyPositionCalculator = () => import('../../src/components/organisms/ModalMyPositionCalculator.vue' /* webpackChunkName: "components/modal-my-position-calculator" */).then(c => wrapFunctional(c.default || c))
export const ModalPremiumContractsAmend = () => import('../../src/components/organisms/ModalPremiumContractsAmend.vue' /* webpackChunkName: "components/modal-premium-contracts-amend" */).then(c => wrapFunctional(c.default || c))
export const ModalPrivacy = () => import('../../src/components/organisms/ModalPrivacy.vue' /* webpackChunkName: "components/modal-privacy" */).then(c => wrapFunctional(c.default || c))
export const ModalRecentTrades = () => import('../../src/components/organisms/ModalRecentTrades.vue' /* webpackChunkName: "components/modal-recent-trades" */).then(c => wrapFunctional(c.default || c))
export const ModalSetIndividualTargetPrice = () => import('../../src/components/organisms/ModalSetIndividualTargetPrice.vue' /* webpackChunkName: "components/modal-set-individual-target-price" */).then(c => wrapFunctional(c.default || c))
export const ModalTargetPricesSet = () => import('../../src/components/organisms/ModalTargetPricesSet.vue' /* webpackChunkName: "components/modal-target-prices-set" */).then(c => wrapFunctional(c.default || c))
export const ModalTermsAndConditionsTrading = () => import('../../src/components/organisms/ModalTermsAndConditionsTrading.vue' /* webpackChunkName: "components/modal-terms-and-conditions-trading" */).then(c => wrapFunctional(c.default || c))
export const ModalTradingRegionalView = () => import('../../src/components/organisms/ModalTradingRegionalView.vue' /* webpackChunkName: "components/modal-trading-regional-view" */).then(c => wrapFunctional(c.default || c))
export const ModalUploadCollectionReceipt = () => import('../../src/components/organisms/ModalUploadCollectionReceipt.vue' /* webpackChunkName: "components/modal-upload-collection-receipt" */).then(c => wrapFunctional(c.default || c))
export const NewsFeed = () => import('../../src/components/organisms/NewsFeed.vue' /* webpackChunkName: "components/news-feed" */).then(c => wrapFunctional(c.default || c))
export const OfferDetail = () => import('../../src/components/organisms/OfferDetail.vue' /* webpackChunkName: "components/offer-detail" */).then(c => wrapFunctional(c.default || c))
export const PremiumContractSelectSalesContract = () => import('../../src/components/organisms/PremiumContractSelectSalesContract.vue' /* webpackChunkName: "components/premium-contract-select-sales-contract" */).then(c => wrapFunctional(c.default || c))
export const PremiumContractSummary = () => import('../../src/components/organisms/PremiumContractSummary.vue' /* webpackChunkName: "components/premium-contract-summary" */).then(c => wrapFunctional(c.default || c))
export const PriceChangeTable = () => import('../../src/components/organisms/PriceChangeTable.vue' /* webpackChunkName: "components/price-change-table" */).then(c => wrapFunctional(c.default || c))
export const RecentTrade = () => import('../../src/components/organisms/RecentTrade.vue' /* webpackChunkName: "components/recent-trade" */).then(c => wrapFunctional(c.default || c))
export const RollContractMovementDetail = () => import('../../src/components/organisms/RollContractMovementDetail.vue' /* webpackChunkName: "components/roll-contract-movement-detail" */).then(c => wrapFunctional(c.default || c))
export const SellerListingCard = () => import('../../src/components/organisms/SellerListingCard.vue' /* webpackChunkName: "components/seller-listing-card" */).then(c => wrapFunctional(c.default || c))
export const SellerTradeRequestCard = () => import('../../src/components/organisms/SellerTradeRequestCard.vue' /* webpackChunkName: "components/seller-trade-request-card" */).then(c => wrapFunctional(c.default || c))
export const SetTargetPrice = () => import('../../src/components/organisms/SetTargetPrice.vue' /* webpackChunkName: "components/set-target-price" */).then(c => wrapFunctional(c.default || c))
export const SpecificationAccordion = () => import('../../src/components/organisms/SpecificationAccordion.vue' /* webpackChunkName: "components/specification-accordion" */).then(c => wrapFunctional(c.default || c))
export const TimelineCardContractRolled = () => import('../../src/components/organisms/TimelineCardContractRolled.vue' /* webpackChunkName: "components/timeline-card-contract-rolled" */).then(c => wrapFunctional(c.default || c))
export const UpdatedAtDateTime = () => import('../../src/components/organisms/UpdatedAtDateTime.vue' /* webpackChunkName: "components/updated-at-date-time" */).then(c => wrapFunctional(c.default || c))
export const HComboBox = () => import('../../src/components/atoms/combo-box/HComboBox.vue' /* webpackChunkName: "components/h-combo-box" */).then(c => wrapFunctional(c.default || c))
export const HComboBoxOption = () => import('../../src/components/atoms/combo-box-option/HComboBoxOption.vue' /* webpackChunkName: "components/h-combo-box-option" */).then(c => wrapFunctional(c.default || c))
export const HRadioButton = () => import('../../src/components/atoms/radio-button/HRadioButton.vue' /* webpackChunkName: "components/h-radio-button" */).then(c => wrapFunctional(c.default || c))
export const RichText = () => import('../../src/components/atoms/rich-text/RichText.vue' /* webpackChunkName: "components/rich-text" */).then(c => wrapFunctional(c.default || c))
export const BannerWrapper = () => import('../../src/components/elements/banner-wrapper/BannerWrapper.vue' /* webpackChunkName: "components/banner-wrapper" */).then(c => wrapFunctional(c.default || c))
export const CheckboxButton = () => import('../../src/components/elements/checkbox-button/CheckboxButton.vue' /* webpackChunkName: "components/checkbox-button" */).then(c => wrapFunctional(c.default || c))
export const HarvestPlansFiltersAccordion = () => import('../../src/components/filters/accordions/HarvestPlansFiltersAccordion.vue' /* webpackChunkName: "components/harvest-plans-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const PremiumContractsFIltersAccordion = () => import('../../src/components/filters/accordions/PremiumContractsFIltersAccordion.vue' /* webpackChunkName: "components/premium-contracts-f-ilters-accordion" */).then(c => wrapFunctional(c.default || c))
export const SalesContractsFiltersAccordion = () => import('../../src/components/filters/accordions/SalesContractsFiltersAccordion.vue' /* webpackChunkName: "components/sales-contracts-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const TradeAvailabilityFiltersAccordion = () => import('../../src/components/filters/accordions/TradeAvailabilityFiltersAccordion.vue' /* webpackChunkName: "components/trade-availability-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const TradedFiltersAccordion = () => import('../../src/components/filters/accordions/TradedFiltersAccordion.vue' /* webpackChunkName: "components/traded-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const TradingBuyerFiltersAccordion = () => import('../../src/components/filters/accordions/TradingBuyerFiltersAccordion.vue' /* webpackChunkName: "components/trading-buyer-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const WantedSellerFiltersAccordion = () => import('../../src/components/filters/accordions/WantedSellerFiltersAccordion.vue' /* webpackChunkName: "components/wanted-seller-filters-accordion" */).then(c => wrapFunctional(c.default || c))
export const HarvestPlansFilterHeader = () => import('../../src/components/filters/headers/HarvestPlansFilterHeader.vue' /* webpackChunkName: "components/harvest-plans-filter-header" */).then(c => wrapFunctional(c.default || c))
export const MarketListingFilterHeader = () => import('../../src/components/filters/headers/MarketListingFilterHeader.vue' /* webpackChunkName: "components/market-listing-filter-header" */).then(c => wrapFunctional(c.default || c))
export const PremiumContractsFilterHeader = () => import('../../src/components/filters/headers/PremiumContractsFilterHeader.vue' /* webpackChunkName: "components/premium-contracts-filter-header" */).then(c => wrapFunctional(c.default || c))
export const SalesContractsFilterHeader = () => import('../../src/components/filters/headers/SalesContractsFilterHeader.vue' /* webpackChunkName: "components/sales-contracts-filter-header" */).then(c => wrapFunctional(c.default || c))
export const TradeAvailabilityFilterHeader = () => import('../../src/components/filters/headers/TradeAvailabilityFilterHeader.vue' /* webpackChunkName: "components/trade-availability-filter-header" */).then(c => wrapFunctional(c.default || c))
export const TradeRequestFilterHeader = () => import('../../src/components/filters/headers/TradeRequestFilterHeader.vue' /* webpackChunkName: "components/trade-request-filter-header" */).then(c => wrapFunctional(c.default || c))
export const TradedFilterHeader = () => import('../../src/components/filters/headers/TradedFilterHeader.vue' /* webpackChunkName: "components/traded-filter-header" */).then(c => wrapFunctional(c.default || c))
export const TradingBuyerFilterHeader = () => import('../../src/components/filters/headers/TradingBuyerFilterHeader.vue' /* webpackChunkName: "components/trading-buyer-filter-header" */).then(c => wrapFunctional(c.default || c))
export const TradingInventoryFilterHeader = () => import('../../src/components/filters/headers/TradingInventoryFilterHeader.vue' /* webpackChunkName: "components/trading-inventory-filter-header" */).then(c => wrapFunctional(c.default || c))
export const WantedSellerFilterHeader = () => import('../../src/components/filters/headers/WantedSellerFilterHeader.vue' /* webpackChunkName: "components/wanted-seller-filter-header" */).then(c => wrapFunctional(c.default || c))
export const FormAssetCategories = () => import('../../src/components/forms/asset-categories/FormAssetCategories.vue' /* webpackChunkName: "components/form-asset-categories" */).then(c => wrapFunctional(c.default || c))
export const CookieBanner = () => import('../../src/components/forms/cookie/cookieBanner.vue' /* webpackChunkName: "components/cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const FormHelp = () => import('../../src/components/forms/help/FormHelp.vue' /* webpackChunkName: "components/form-help" */).then(c => wrapFunctional(c.default || c))
export const FormOrganisation = () => import('../../src/components/forms/organisation/FormOrganisation.vue' /* webpackChunkName: "components/form-organisation" */).then(c => wrapFunctional(c.default || c))
export const FormCarrierTermsAndConditions = () => import('../../src/components/forms/settings/FormCarrierTermsAndConditions.vue' /* webpackChunkName: "components/form-carrier-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const FormSecurity = () => import('../../src/components/forms/settings/FormSecurity.vue' /* webpackChunkName: "components/form-security" */).then(c => wrapFunctional(c.default || c))
export const FormTermsAndConditions = () => import('../../src/components/forms/terms-and-conditions/FormTermsAndConditions.vue' /* webpackChunkName: "components/form-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const GuardAuth = () => import('../../src/components/guards/auth/GuardAuth.vue' /* webpackChunkName: "components/guard-auth" */).then(c => wrapFunctional(c.default || c))
export const Guard = () => import('../../src/components/guards/guard/Guard.vue' /* webpackChunkName: "components/guard" */).then(c => wrapFunctional(c.default || c))
export const HarvestedProgress = () => import('../../src/components/inventory/harvest/HarvestedProgress.vue' /* webpackChunkName: "components/harvested-progress" */).then(c => wrapFunctional(c.default || c))
export const StorageNoteCard = () => import('../../src/components/inventory/stores/StorageNoteCard.vue' /* webpackChunkName: "components/storage-note-card" */).then(c => wrapFunctional(c.default || c))
export const AppLoader = () => import('../../src/components/layouts/app-loader/AppLoader.vue' /* webpackChunkName: "components/app-loader" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../src/components/layouts/footer/LayoutFooter.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutPadder = () => import('../../src/components/layouts/layout-padder/LayoutPadder.vue' /* webpackChunkName: "components/layout-padder" */).then(c => wrapFunctional(c.default || c))
export const LayoutPageHeader = () => import('../../src/components/layouts/page/LayoutPageHeader.vue' /* webpackChunkName: "components/layout-page-header" */).then(c => wrapFunctional(c.default || c))
export const ModalBase = () => import('../../src/components/modals/modal-base/ModalBase.vue' /* webpackChunkName: "components/modal-base" */).then(c => wrapFunctional(c.default || c))
export const ModalWrapper = () => import('../../src/components/modals/modal-wrapper/ModalWrapper.vue' /* webpackChunkName: "components/modal-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ContactUsCard = () => import('../../src/components/molecules/contact-us-card/ContactUsCard.vue' /* webpackChunkName: "components/contact-us-card" */).then(c => wrapFunctional(c.default || c))
export const EmptySearch = () => import('../../src/components/molecules/empty-search/EmptySearch.vue' /* webpackChunkName: "components/empty-search" */).then(c => wrapFunctional(c.default || c))
export const EndListingCta = () => import('../../src/components/molecules/end-listing-cta/EndListingCta.vue' /* webpackChunkName: "components/end-listing-cta" */).then(c => wrapFunctional(c.default || c))
export const HLottie = () => import('../../src/components/molecules/h-lottie/HLottie.vue' /* webpackChunkName: "components/h-lottie" */).then(c => wrapFunctional(c.default || c))
export const HelpButton = () => import('../../src/components/molecules/help-button/HelpButton.vue' /* webpackChunkName: "components/help-button" */).then(c => wrapFunctional(c.default || c))
export const HelpCard = () => import('../../src/components/molecules/help-card/HelpCard.vue' /* webpackChunkName: "components/help-card" */).then(c => wrapFunctional(c.default || c))
export const InformationalBanner = () => import('../../src/components/molecules/informational-banner/InformationalBanner.vue' /* webpackChunkName: "components/informational-banner" */).then(c => wrapFunctional(c.default || c))
export const ListingSupportCard = () => import('../../src/components/molecules/listing-support-card/ListingSupportCard.vue' /* webpackChunkName: "components/listing-support-card" */).then(c => wrapFunctional(c.default || c))
export const ListingSupportDetails = () => import('../../src/components/molecules/listing-support-details/listingSupportDetails.vue' /* webpackChunkName: "components/listing-support-details" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsCarouselItem = () => import('../../src/components/molecules/market-insights-carousel-item/MarketInsightsCarouselItem.vue' /* webpackChunkName: "components/market-insights-carousel-item" */).then(c => wrapFunctional(c.default || c))
export const MonthPickerCarousel = () => import('../../src/components/molecules/month-picker-carousel/MonthPickerCarousel.vue' /* webpackChunkName: "components/month-picker-carousel" */).then(c => wrapFunctional(c.default || c))
export const MultiSelectRegions = () => import('../../src/components/molecules/multi-select-regions/MultiSelectRegions.vue' /* webpackChunkName: "components/multi-select-regions" */).then(c => wrapFunctional(c.default || c))
export const MultiStepCard = () => import('../../src/components/molecules/multi-step-card/MultiStepCard.vue' /* webpackChunkName: "components/multi-step-card" */).then(c => wrapFunctional(c.default || c))
export const Steps = () => import('../../src/components/molecules/steps/Steps.vue' /* webpackChunkName: "components/steps" */).then(c => wrapFunctional(c.default || c))
export const TradeDetails = () => import('../../src/components/molecules/trade-details/TradeDetails.vue' /* webpackChunkName: "components/trade-details" */).then(c => wrapFunctional(c.default || c))
export const CropCombinationForm = () => import('../../src/components/trading/crop-needs/CropCombinationForm.vue' /* webpackChunkName: "components/crop-combination-form" */).then(c => wrapFunctional(c.default || c))
export const CropCombinationSelector = () => import('../../src/components/trading/crop-needs/CropCombinationSelector.vue' /* webpackChunkName: "components/crop-combination-selector" */).then(c => wrapFunctional(c.default || c))
export const TradeRequests = () => import('../../src/components/trading/crop-needs/TradeRequests.vue' /* webpackChunkName: "components/trade-requests" */).then(c => wrapFunctional(c.default || c))
export const BidForm = () => import('../../src/components/trading/forms/BidForm.vue' /* webpackChunkName: "components/bid-form" */).then(c => wrapFunctional(c.default || c))
export const BidAcceptedOverview = () => import('../../src/components/trading/external-bids/BidAcceptedOverview.vue' /* webpackChunkName: "components/bid-accepted-overview" */).then(c => wrapFunctional(c.default || c))
export const BuyerBidListingDetails = () => import('../../src/components/trading/external-bids/BuyerBidListingDetails.vue' /* webpackChunkName: "components/buyer-bid-listing-details" */).then(c => wrapFunctional(c.default || c))
export const BuyerBidTitleSummary = () => import('../../src/components/trading/external-bids/BuyerBidTitleSummary.vue' /* webpackChunkName: "components/buyer-bid-title-summary" */).then(c => wrapFunctional(c.default || c))
export const FullNotificationBidAcceptanceTimeframeExpired = () => import('../../src/components/trading/external-bids/FullNotificationBidAcceptanceTimeframeExpired.vue' /* webpackChunkName: "components/full-notification-bid-acceptance-timeframe-expired" */).then(c => wrapFunctional(c.default || c))
export const FullNotificationMarketListingEnded = () => import('../../src/components/trading/external-bids/FullNotificationMarketListingEnded.vue' /* webpackChunkName: "components/full-notification-market-listing-ended" */).then(c => wrapFunctional(c.default || c))
export const NotificationBidActive = () => import('../../src/components/trading/external-bids/NotificationBidActive.vue' /* webpackChunkName: "components/notification-bid-active" */).then(c => wrapFunctional(c.default || c))
export const NotificationBidExpired = () => import('../../src/components/trading/external-bids/NotificationBidExpired.vue' /* webpackChunkName: "components/notification-bid-expired" */).then(c => wrapFunctional(c.default || c))
export const NotificationBidStatusAccepted = () => import('../../src/components/trading/external-bids/NotificationBidStatusAccepted.vue' /* webpackChunkName: "components/notification-bid-status-accepted" */).then(c => wrapFunctional(c.default || c))
export const NotificationBidStatusWithdrawn = () => import('../../src/components/trading/external-bids/NotificationBidStatusWithdrawn.vue' /* webpackChunkName: "components/notification-bid-status-withdrawn" */).then(c => wrapFunctional(c.default || c))
export const NotificationListingEnded = () => import('../../src/components/trading/external-bids/NotificationListingEnded.vue' /* webpackChunkName: "components/notification-listing-ended" */).then(c => wrapFunctional(c.default || c))
export const MarketInsight = () => import('../../src/components/trading/market-insights/MarketInsight.vue' /* webpackChunkName: "components/market-insight" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightSummary = () => import('../../src/components/trading/market-insights/MarketInsightSummary.vue' /* webpackChunkName: "components/market-insight-summary" */).then(c => wrapFunctional(c.default || c))
export const MarketInsightsTickerTape = () => import('../../src/components/trading/market-insights/MarketInsightsTickerTape.vue' /* webpackChunkName: "components/market-insights-ticker-tape" */).then(c => wrapFunctional(c.default || c))
export const BuyerMarketListingDetails = () => import('../../src/components/trading/market-listing/BuyerMarketListingDetails.vue' /* webpackChunkName: "components/buyer-market-listing-details" */).then(c => wrapFunctional(c.default || c))
export const MarketListings = () => import('../../src/components/trading/market-listing/MarketListings.vue' /* webpackChunkName: "components/market-listings" */).then(c => wrapFunctional(c.default || c))
export const DeliveryAndSettlementDisplay = () => import('../../src/components/trading/sales-contracts/DeliveryAndSettlementDisplay.vue' /* webpackChunkName: "components/delivery-and-settlement-display" */).then(c => wrapFunctional(c.default || c))
export const FixContractPrice = () => import('../../src/components/trading/sales-contracts/FixContractPrice.vue' /* webpackChunkName: "components/fix-contract-price" */).then(c => wrapFunctional(c.default || c))
export const FulfilmentAndSettlementActions = () => import('../../src/components/trading/sales-contracts/FulfilmentAndSettlementActions.vue' /* webpackChunkName: "components/fulfilment-and-settlement-actions" */).then(c => wrapFunctional(c.default || c))
export const SalesContractSelect = () => import('../../src/components/trading/sales-contracts/SalesContractSelect.vue' /* webpackChunkName: "components/sales-contract-select" */).then(c => wrapFunctional(c.default || c))
export const AssuranceCertificate = () => import('../../src/components/organisms/assurance-certificate-card/AssuranceCertificate.vue' /* webpackChunkName: "components/assurance-certificate" */).then(c => wrapFunctional(c.default || c))
export const AssuranceCertificateMini = () => import('../../src/components/organisms/assurance-certificate-mini/AssuranceCertificateMini.vue' /* webpackChunkName: "components/assurance-certificate-mini" */).then(c => wrapFunctional(c.default || c))
export const AssuranceSchemeSelect = () => import('../../src/components/organisms/assurance-scheme-select/AssuranceSchemeSelect.vue' /* webpackChunkName: "components/assurance-scheme-select" */).then(c => wrapFunctional(c.default || c))
export const BidAcceptedTradeBuyerSummary = () => import('../../src/components/organisms/bid-accepted-trade-buyer-summary/BidAcceptedTradeBuyerSummary.vue' /* webpackChunkName: "components/bid-accepted-trade-buyer-summary" */).then(c => wrapFunctional(c.default || c))
export const BuyerBidListingDetailSellerSummary = () => import('../../src/components/organisms/buyer-bid-listing-detail-seller-summary/BuyerBidListingDetailSellerSummary.vue' /* webpackChunkName: "components/buyer-bid-listing-detail-seller-summary" */).then(c => wrapFunctional(c.default || c))
export const BuyerListingSpecification = () => import('../../src/components/organisms/buyer-listing-specification/BuyerListingSpecification.vue' /* webpackChunkName: "components/buyer-listing-specification" */).then(c => wrapFunctional(c.default || c))
export const BuyerPriceOptionCard = () => import('../../src/components/organisms/buyer-price-option-card/BuyerPriceOptionCard.vue' /* webpackChunkName: "components/buyer-price-option-card" */).then(c => wrapFunctional(c.default || c))
export const BuyerWantedSummary = () => import('../../src/components/organisms/buyer-wanted-summary/BuyerWantedSummary.vue' /* webpackChunkName: "components/buyer-wanted-summary" */).then(c => wrapFunctional(c.default || c))
export const CaptureRelistFeedbackModal = () => import('../../src/components/organisms/capture-relist-feedback-modal/CaptureRelistFeedbackModal.vue' /* webpackChunkName: "components/capture-relist-feedback-modal" */).then(c => wrapFunctional(c.default || c))
export const DraftListingPrompt = () => import('../../src/components/organisms/draft-listing-prompt/DraftListingPrompt.vue' /* webpackChunkName: "components/draft-listing-prompt" */).then(c => wrapFunctional(c.default || c))
export const FormBusinessUnitSelect = () => import('../../src/components/organisms/form-business-unit-select/FormBusinessUnitSelect.vue' /* webpackChunkName: "components/form-business-unit-select" */).then(c => wrapFunctional(c.default || c))
export const GettingStartedCard = () => import('../../src/components/organisms/getting-started-card/GettingStartedCard.vue' /* webpackChunkName: "components/getting-started-card" */).then(c => wrapFunctional(c.default || c))
export const GettingStartedCardContent = () => import('../../src/components/organisms/getting-started-card-content/GettingStartedCardContent.vue' /* webpackChunkName: "components/getting-started-card-content" */).then(c => wrapFunctional(c.default || c))
export const HectareVerifiedCard = () => import('../../src/components/organisms/hectare-verified-card/HectareVerifiedCard.vue' /* webpackChunkName: "components/hectare-verified-card" */).then(c => wrapFunctional(c.default || c))
export const HelpCentreLayout = () => import('../../src/components/organisms/help-centre-layout/HelpCentreLayout.vue' /* webpackChunkName: "components/help-centre-layout" */).then(c => wrapFunctional(c.default || c))
export const ModalContactCustomerSuccess = () => import('../../src/components/organisms/modal-contact-customer-success/ModalContactCustomerSuccess.vue' /* webpackChunkName: "components/modal-contact-customer-success" */).then(c => wrapFunctional(c.default || c))
export const ModalFAQ = () => import('../../src/components/organisms/modal-faq/ModalFAQ.vue' /* webpackChunkName: "components/modal-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const ModalHelp = () => import('../../src/components/organisms/modal-help/ModalHelp.vue' /* webpackChunkName: "components/modal-help" */).then(c => wrapFunctional(c.default || c))
export const ModalUserImpersonateOptions = () => import('../../src/components/organisms/modal-impersonate-user-options/ModalUserImpersonateOptions.vue' /* webpackChunkName: "components/modal-user-impersonate-options" */).then(c => wrapFunctional(c.default || c))
export const ModalWhatsNew = () => import('../../src/components/organisms/modal-whats-new/ModalWhatsNew.vue' /* webpackChunkName: "components/modal-whats-new" */).then(c => wrapFunctional(c.default || c))
export const MovementDateSelectMonthCarousel = () => import('../../src/components/organisms/movement-date-select-month-carousel/MovementDateSelectMonthCarousel.vue' /* webpackChunkName: "components/movement-date-select-month-carousel" */).then(c => wrapFunctional(c.default || c))
export const SellerAcceptedBids = () => import('../../src/components/organisms/seller-accepted-bids/SellerAcceptedBids.vue' /* webpackChunkName: "components/seller-accepted-bids" */).then(c => wrapFunctional(c.default || c))
export const AcceptedBidCard = () => import('../../src/components/organisms/seller-bid-card/AcceptedBidCard.vue' /* webpackChunkName: "components/accepted-bid-card" */).then(c => wrapFunctional(c.default || c))
export const SellerListingSpecification = () => import('../../src/components/organisms/seller-listing-specification/SellerListingSpecification.vue' /* webpackChunkName: "components/seller-listing-specification" */).then(c => wrapFunctional(c.default || c))
export const SellerListingStatusNotification = () => import('../../src/components/organisms/seller-listing-status-notification/SellerListingStatusNotification.vue' /* webpackChunkName: "components/seller-listing-status-notification" */).then(c => wrapFunctional(c.default || c))
export const SellerOfferDetailCard = () => import('../../src/components/organisms/seller-offer-detail-card/SellerOfferDetailCard.vue' /* webpackChunkName: "components/seller-offer-detail-card" */).then(c => wrapFunctional(c.default || c))
export const SellerRelistListingDetail = () => import('../../src/components/organisms/seller-relist-listing-detail/SellerRelistListingDetail.vue' /* webpackChunkName: "components/seller-relist-listing-detail" */).then(c => wrapFunctional(c.default || c))
export const UnlockTradePositionPrompt = () => import('../../src/components/organisms/unlock-trade-position-prompt/UnlockTradePositionPrompt.vue' /* webpackChunkName: "components/unlock-trade-position-prompt" */).then(c => wrapFunctional(c.default || c))
export const WantedListingDetailsSummary = () => import('../../src/components/organisms/wanted-listing-details-summary/WantedListingDetailsSummary.vue' /* webpackChunkName: "components/wanted-listing-details-summary" */).then(c => wrapFunctional(c.default || c))
export const WelcomeBanner = () => import('../../src/components/organisms/welcome-banner/WelcomeBanner.vue' /* webpackChunkName: "components/welcome-banner" */).then(c => wrapFunctional(c.default || c))
export const ApiLoadMore = () => import('../../src/components/utils/api-load-more/ApiLoadMore.vue' /* webpackChunkName: "components/api-load-more" */).then(c => wrapFunctional(c.default || c))
export const DevDebug = () => import('../../src/components/utils/dev-debug/DevDebug.vue' /* webpackChunkName: "components/dev-debug" */).then(c => wrapFunctional(c.default || c))
export const IframeEmbed = () => import('../../src/components/utils/iframe-embed/IframeEmbed.vue' /* webpackChunkName: "components/iframe-embed" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../src/components/utils/loader/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const ScriptLoader = () => import('../../src/components/utils/script-loader/ScriptLoader.vue' /* webpackChunkName: "components/script-loader" */).then(c => wrapFunctional(c.default || c))
export const AccordionCard = () => import('../../src/components/elements/base/accordion/AccordionCard.vue' /* webpackChunkName: "components/accordion-card" */).then(c => wrapFunctional(c.default || c))
export const AccordionList = () => import('../../src/components/elements/base/accordion/AccordionList.vue' /* webpackChunkName: "components/accordion-list" */).then(c => wrapFunctional(c.default || c))
export const Avatar = () => import('../../src/components/elements/base/avatar/Avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const BadgeCount = () => import('../../src/components/elements/base/badge-count/BadgeCount.vue' /* webpackChunkName: "components/badge-count" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../src/components/elements/base/banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../src/components/elements/base/button/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const ButtonDropdown = () => import('../../src/components/elements/base/button-dropdown/ButtonDropdown.vue' /* webpackChunkName: "components/button-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ChartDoughnut = () => import('../../src/components/elements/base/chart/ChartDoughnut.vue' /* webpackChunkName: "components/chart-doughnut" */).then(c => wrapFunctional(c.default || c))
export const ChartLine = () => import('../../src/components/elements/base/chart/ChartLine.vue' /* webpackChunkName: "components/chart-line" */).then(c => wrapFunctional(c.default || c))
export const ChartPercentageBar = () => import('../../src/components/elements/base/chart/ChartPercentageBar.vue' /* webpackChunkName: "components/chart-percentage-bar" */).then(c => wrapFunctional(c.default || c))
export const ContactCard = () => import('../../src/components/elements/base/contact-card/ContactCard.vue' /* webpackChunkName: "components/contact-card" */).then(c => wrapFunctional(c.default || c))
export const ContactNumber = () => import('../../src/components/elements/base/contact-number/ContactNumber.vue' /* webpackChunkName: "components/contact-number" */).then(c => wrapFunctional(c.default || c))
export const ContentSwitcher = () => import('../../src/components/elements/base/content-switcher/ContentSwitcher.vue' /* webpackChunkName: "components/content-switcher" */).then(c => wrapFunctional(c.default || c))
export const Countdown = () => import('../../src/components/elements/base/countdown/Countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => wrapFunctional(c.default || c))
export const CountdownTag = () => import('../../src/components/elements/base/countdown-tag/CountdownTag.vue' /* webpackChunkName: "components/countdown-tag" */).then(c => wrapFunctional(c.default || c))
export const DevSiteBanner = () => import('../../src/components/elements/base/dev-site-banner/DevSiteBanner.vue' /* webpackChunkName: "components/dev-site-banner" */).then(c => wrapFunctional(c.default || c))
export const DropdownMenuList = () => import('../../src/components/elements/base/dropdown-menu-list/DropdownMenuList.vue' /* webpackChunkName: "components/dropdown-menu-list" */).then(c => wrapFunctional(c.default || c))
export const MobileDropdownMenuOverlay = () => import('../../src/components/elements/base/dropdown-menu-list/MobileDropdownMenuOverlay.vue' /* webpackChunkName: "components/mobile-dropdown-menu-overlay" */).then(c => wrapFunctional(c.default || c))
export const Empty = () => import('../../src/components/elements/base/empty/Empty.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c))
export const FooterBar = () => import('../../src/components/elements/base/footer-bar/FooterBar.vue' /* webpackChunkName: "components/footer-bar" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../src/components/elements/base/icon/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const Img = () => import('../../src/components/elements/base/image/Img.vue' /* webpackChunkName: "components/img" */).then(c => wrapFunctional(c.default || c))
export const LicensePlate = () => import('../../src/components/elements/base/license-plate/LicensePlate.vue' /* webpackChunkName: "components/license-plate" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../src/components/elements/base/link/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const AccountNotification = () => import('../../src/components/elements/base/notification/AccountNotification.vue' /* webpackChunkName: "components/account-notification" */).then(c => wrapFunctional(c.default || c))
export const AccountNotificationWrapper = () => import('../../src/components/elements/base/notification/AccountNotificationWrapper.vue' /* webpackChunkName: "components/account-notification-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../src/components/elements/base/notification/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const NotificationFloating = () => import('../../src/components/elements/base/notification/NotificationFloating.vue' /* webpackChunkName: "components/notification-floating" */).then(c => wrapFunctional(c.default || c))
export const NotificationFloatingWrapper = () => import('../../src/components/elements/base/notification/NotificationFloatingWrapper.vue' /* webpackChunkName: "components/notification-floating-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../src/components/elements/base/pagination/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const Panel = () => import('../../src/components/elements/base/panel/Panel.vue' /* webpackChunkName: "components/panel" */).then(c => wrapFunctional(c.default || c))
export const PhoneLocale = () => import('../../src/components/elements/base/phone-locale/PhoneLocale.vue' /* webpackChunkName: "components/phone-locale" */).then(c => wrapFunctional(c.default || c))
export const Pill = () => import('../../src/components/elements/base/pill/Pill.vue' /* webpackChunkName: "components/pill" */).then(c => wrapFunctional(c.default || c))
export const PillSelect = () => import('../../src/components/elements/base/pill-select/PillSelect.vue' /* webpackChunkName: "components/pill-select" */).then(c => wrapFunctional(c.default || c))
export const Placeholder = () => import('../../src/components/elements/base/placeholder/Placeholder.vue' /* webpackChunkName: "components/placeholder" */).then(c => wrapFunctional(c.default || c))
export const Popover = () => import('../../src/components/elements/base/popover/Popover.vue' /* webpackChunkName: "components/popover" */).then(c => wrapFunctional(c.default || c))
export const ProgressBarWrapper = () => import('../../src/components/elements/base/progress-bar-wrapper/ProgressBarWrapper.vue' /* webpackChunkName: "components/progress-bar-wrapper" */).then(c => wrapFunctional(c.default || c))
export const SelectTileItem = () => import('../../src/components/elements/base/select-tile-item/SelectTileItem.vue' /* webpackChunkName: "components/select-tile-item" */).then(c => wrapFunctional(c.default || c))
export const Stat = () => import('../../src/components/elements/base/stat/Stat.vue' /* webpackChunkName: "components/stat" */).then(c => wrapFunctional(c.default || c))
export const Table = () => import('../../src/components/elements/base/table/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const TableMobile = () => import('../../src/components/elements/base/table/TableMobile.vue' /* webpackChunkName: "components/table-mobile" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../src/components/elements/base/tabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const InfoModalTag = () => import('../../src/components/elements/base/tag/InfoModalTag.vue' /* webpackChunkName: "components/info-modal-tag" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../src/components/elements/base/tag/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const TickerTape = () => import('../../src/components/elements/base/ticker-tape/TickerTape.vue' /* webpackChunkName: "components/ticker-tape" */).then(c => wrapFunctional(c.default || c))
export const TimelineCard = () => import('../../src/components/elements/base/timeline-card/TimelineCard.vue' /* webpackChunkName: "components/timeline-card" */).then(c => wrapFunctional(c.default || c))
export const ToolTip = () => import('../../src/components/elements/base/tooltip/ToolTip.vue' /* webpackChunkName: "components/tool-tip" */).then(c => wrapFunctional(c.default || c))
export const ToolTipCard = () => import('../../src/components/elements/base/tooltip/ToolTipCard.vue' /* webpackChunkName: "components/tool-tip-card" */).then(c => wrapFunctional(c.default || c))
export const Currency = () => import('../../src/components/elements/financial/currency-display/Currency.vue' /* webpackChunkName: "components/currency" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../src/components/elements/form/date-picker/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const ErrorSummary = () => import('../../src/components/elements/form/error-summary/ErrorSummary.vue' /* webpackChunkName: "components/error-summary" */).then(c => wrapFunctional(c.default || c))
export const Field = () => import('../../src/components/elements/form/field/Field.vue' /* webpackChunkName: "components/field" */).then(c => wrapFunctional(c.default || c))
export const FileUploadCore = () => import('../../src/components/elements/form/file-upload/FileUploadCore.vue' /* webpackChunkName: "components/file-upload-core" */).then(c => wrapFunctional(c.default || c))
export const FileUploadInput = () => import('../../src/components/elements/form/file-upload/FileUploadInput.vue' /* webpackChunkName: "components/file-upload-input" */).then(c => wrapFunctional(c.default || c))
export const FileUploadList = () => import('../../src/components/elements/form/file-upload/FileUploadList.vue' /* webpackChunkName: "components/file-upload-list" */).then(c => wrapFunctional(c.default || c))
export const Form = () => import('../../src/components/elements/form/form/Form.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c))
export const FormGrid = () => import('../../src/components/elements/form/form-grid/FormGrid.vue' /* webpackChunkName: "components/form-grid" */).then(c => wrapFunctional(c.default || c))
export const FormRow = () => import('../../src/components/elements/form/form-row/FormRow.vue' /* webpackChunkName: "components/form-row" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../src/components/elements/form/input/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const Label = () => import('../../src/components/elements/form/label/Label.vue' /* webpackChunkName: "components/label" */).then(c => wrapFunctional(c.default || c))
export const MonthPicker = () => import('../../src/components/elements/form/month-picker/MonthPicker.vue' /* webpackChunkName: "components/month-picker" */).then(c => wrapFunctional(c.default || c))
export const MultiSelect = () => import('../../src/components/elements/form/multi-select/MultiSelect.vue' /* webpackChunkName: "components/multi-select" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../src/components/elements/form/select/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const SelectTileInput = () => import('../../src/components/elements/form/select-tile-input/SelectTileInput.vue' /* webpackChunkName: "components/select-tile-input" */).then(c => wrapFunctional(c.default || c))
export const Toggle = () => import('../../src/components/elements/form/toggle/Toggle.vue' /* webpackChunkName: "components/toggle" */).then(c => wrapFunctional(c.default || c))
export const ToggleGroup = () => import('../../src/components/elements/form/toggle-group/ToggleGroup.vue' /* webpackChunkName: "components/toggle-group" */).then(c => wrapFunctional(c.default || c))
export const Address = () => import('../../src/components/elements/geo/address/Address.vue' /* webpackChunkName: "components/address" */).then(c => wrapFunctional(c.default || c))
export const CurrentLocationAnimation = () => import('../../src/components/elements/geo/current-location-animation/CurrentLocationAnimation.vue' /* webpackChunkName: "components/current-location-animation" */).then(c => wrapFunctional(c.default || c))
export const Flag = () => import('../../src/components/elements/geo/flag/Flag.vue' /* webpackChunkName: "components/flag" */).then(c => wrapFunctional(c.default || c))
export const LocationArrow = () => import('../../src/components/elements/geo/location-arrow/LocationArrow.vue' /* webpackChunkName: "components/location-arrow" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../src/components/elements/geo/map/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../src/components/elements/typography/heading/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const CreateAccountForm = () => import('../../src/components/forms/auth/create-account/CreateAccountForm.vue' /* webpackChunkName: "components/create-account-form" */).then(c => wrapFunctional(c.default || c))
export const SetupBusinessForm = () => import('../../src/components/forms/auth/create-account/SetupBusinessForm.vue' /* webpackChunkName: "components/setup-business-form" */).then(c => wrapFunctional(c.default || c))
export const FormForgottenPassword = () => import('../../src/components/forms/auth/forgotten-password/FormForgottenPassword.vue' /* webpackChunkName: "components/form-forgotten-password" */).then(c => wrapFunctional(c.default || c))
export const FormResetPassword = () => import('../../src/components/forms/auth/forgotten-password/FormResetPassword.vue' /* webpackChunkName: "components/form-reset-password" */).then(c => wrapFunctional(c.default || c))
export const FormLogin = () => import('../../src/components/forms/auth/login/FormLogin.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c))
export const FormLoginWithCode = () => import('../../src/components/forms/auth/login/FormLoginWithCode.vue' /* webpackChunkName: "components/form-login-with-code" */).then(c => wrapFunctional(c.default || c))
export const FormSetPassword = () => import('../../src/components/forms/auth/set-password/FormSetPassword.vue' /* webpackChunkName: "components/form-set-password" */).then(c => wrapFunctional(c.default || c))
export const FormRequestAccess = () => import('../../src/components/forms/trading/access/FormRequestAccess.vue' /* webpackChunkName: "components/form-request-access" */).then(c => wrapFunctional(c.default || c))
export const FormMarketListingNew = () => import('../../src/components/forms/trading/availability/FormMarketListingNew.vue' /* webpackChunkName: "components/form-market-listing-new" */).then(c => wrapFunctional(c.default || c))
export const FormInventoryLite = () => import('../../src/components/forms/trading/availability-records/FormInventoryLite.vue' /* webpackChunkName: "components/form-inventory-lite" */).then(c => wrapFunctional(c.default || c))
export const FormInventoryLiteRow = () => import('../../src/components/forms/trading/availability-records/FormInventoryLiteRow.vue' /* webpackChunkName: "components/form-inventory-lite-row" */).then(c => wrapFunctional(c.default || c))
export const FormTradeAvailabilityRecordsNew = () => import('../../src/components/forms/trading/availability-records/FormTradeAvailabilityRecordsNew.vue' /* webpackChunkName: "components/form-trade-availability-records-new" */).then(c => wrapFunctional(c.default || c))
export const FormTradeRequestNew = () => import('../../src/components/forms/trading/crop-needs/FormTradeRequestNew.vue' /* webpackChunkName: "components/form-trade-request-new" */).then(c => wrapFunctional(c.default || c))
export const FormAmendSalesContract = () => import('../../src/components/forms/trading/sales-contracts/FormAmendSalesContract.vue' /* webpackChunkName: "components/form-amend-sales-contract" */).then(c => wrapFunctional(c.default || c))
export const FormEditSettlementAndDelivery = () => import('../../src/components/forms/trading/sales-contracts/FormEditSettlementAndDelivery.vue' /* webpackChunkName: "components/form-edit-settlement-and-delivery" */).then(c => wrapFunctional(c.default || c))
export const FormRollingContract = () => import('../../src/components/forms/trading/sales-contracts/FormRollingContract.vue' /* webpackChunkName: "components/form-rolling-contract" */).then(c => wrapFunctional(c.default || c))
export const FormSalesContractDetail = () => import('../../src/components/forms/trading/sales-contracts/FormSalesContractDetail.vue' /* webpackChunkName: "components/form-sales-contract-detail" */).then(c => wrapFunctional(c.default || c))
export const FormSalesContractEdit = () => import('../../src/components/forms/trading/sales-contracts/FormSalesContractEdit.vue' /* webpackChunkName: "components/form-sales-contract-edit" */).then(c => wrapFunctional(c.default || c))
export const FormConfirmHarvest = () => import('../../src/components/inventory/forms/confirm-harvest/FormConfirmHarvest.vue' /* webpackChunkName: "components/form-confirm-harvest" */).then(c => wrapFunctional(c.default || c))
export const FormHarvestLoadsNew = () => import('../../src/components/inventory/forms/harvest-plan/FormHarvestLoadsNew.vue' /* webpackChunkName: "components/form-harvest-loads-new" */).then(c => wrapFunctional(c.default || c))
export const HarvestLoad = () => import('../../src/components/inventory/forms/harvest-plan/HarvestLoad.vue' /* webpackChunkName: "components/harvest-load" */).then(c => wrapFunctional(c.default || c))
export const FormDryingServiceMatrix = () => import('../../src/components/inventory/forms/storage-contracts/FormDryingServiceMatrix.vue' /* webpackChunkName: "components/form-drying-service-matrix" */).then(c => wrapFunctional(c.default || c))
export const ColsPrimarySecondaryScroll = () => import('../../src/components/layouts/columns/cols-primary-secondary-scroll/ColsPrimarySecondaryScroll.vue' /* webpackChunkName: "components/cols-primary-secondary-scroll" */).then(c => wrapFunctional(c.default || c))
export const NativeStatusBar = () => import('../../src/components/layouts/native/status-bar/NativeStatusBar.vue' /* webpackChunkName: "components/native-status-bar" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessUnitDelete = () => import('../../src/components/modals/account/modal-business-unit-delete/ModalBusinessUnitDelete.vue' /* webpackChunkName: "components/modal-business-unit-delete" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessUnitDeleteError = () => import('../../src/components/modals/account/modal-business-unit-delete-error/ModalBusinessUnitDeleteError.vue' /* webpackChunkName: "components/modal-business-unit-delete-error" */).then(c => wrapFunctional(c.default || c))
export const ModalDataSeedNew = () => import('../../src/components/modals/admin/data-seed-new/ModalDataSeedNew.vue' /* webpackChunkName: "components/modal-data-seed-new" */).then(c => wrapFunctional(c.default || c))
export const ModalDriverLogView = () => import('../../src/components/modals/admin/driver-log-view/ModalDriverLogView.vue' /* webpackChunkName: "components/modal-driver-log-view" */).then(c => wrapFunctional(c.default || c))
export const ModalImpersonate = () => import('../../src/components/modals/admin/impersonate/ModalImpersonate.vue' /* webpackChunkName: "components/modal-impersonate" */).then(c => wrapFunctional(c.default || c))
export const ModalLogView = () => import('../../src/components/modals/admin/log-view/ModalLogView.vue' /* webpackChunkName: "components/modal-log-view" */).then(c => wrapFunctional(c.default || c))
export const ModalNew = () => import('../../src/components/modals/admin/new/ModalNew.vue' /* webpackChunkName: "components/modal-new" */).then(c => wrapFunctional(c.default || c))
export const ModalOrganisationEdit = () => import('../../src/components/modals/admin/organisation-edit/ModalOrganisationEdit.vue' /* webpackChunkName: "components/modal-organisation-edit" */).then(c => wrapFunctional(c.default || c))
export const ModalOrganisationNew = () => import('../../src/components/modals/admin/organisation-new/ModalOrganisationNew.vue' /* webpackChunkName: "components/modal-organisation-new" */).then(c => wrapFunctional(c.default || c))
export const ModalAssetCategoriesNew = () => import('../../src/components/modals/asset-categories/asset-categories-new/ModalAssetCategoriesNew.vue' /* webpackChunkName: "components/modal-asset-categories-new" */).then(c => wrapFunctional(c.default || c))
export const ModalDebug = () => import('../../src/components/modals/global/debug/ModalDebug.vue' /* webpackChunkName: "components/modal-debug" */).then(c => wrapFunctional(c.default || c))
export const ModalDetailDescription = () => import('../../src/components/modals/global/detail-description/ModalDetailDescription.vue' /* webpackChunkName: "components/modal-detail-description" */).then(c => wrapFunctional(c.default || c))
export const ModalFilter = () => import('../../src/components/modals/global/filters/ModalFilter.vue' /* webpackChunkName: "components/modal-filter" */).then(c => wrapFunctional(c.default || c))
export const ModalMobileProfile = () => import('../../src/components/modals/global/mobile-profile/ModalMobileProfile.vue' /* webpackChunkName: "components/modal-mobile-profile" */).then(c => wrapFunctional(c.default || c))
export const ModalNotifications = () => import('../../src/components/modals/global/notifications/ModalNotifications.vue' /* webpackChunkName: "components/modal-notifications" */).then(c => wrapFunctional(c.default || c))
export const ModalAddEditNewField = () => import('../../src/components/modals/inventory/add-edit-new-field/ModalAddEditNewField.vue' /* webpackChunkName: "components/modal-add-edit-new-field" */).then(c => wrapFunctional(c.default || c))
export const ModalAddEditNewStore = () => import('../../src/components/modals/inventory/add-edit-new-store/ModalAddEditNewStore.vue' /* webpackChunkName: "components/modal-add-edit-new-store" */).then(c => wrapFunctional(c.default || c))
export const ModalAssetCsv = () => import('../../src/components/modals/inventory/asset-csv/ModalAssetCsv.vue' /* webpackChunkName: "components/modal-asset-csv" */).then(c => wrapFunctional(c.default || c))
export const ModalAssetManage = () => import('../../src/components/modals/inventory/asset-manage/ModalAssetManage.vue' /* webpackChunkName: "components/modal-asset-manage" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessConnectionAddNew = () => import('../../src/components/modals/inventory/business-connection-add-new/ModalBusinessConnectionAddNew.vue' /* webpackChunkName: "components/modal-business-connection-add-new" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessConnectionAddNewContact = () => import('../../src/components/modals/inventory/business-connection-add-new-contact/ModalBusinessConnectionAddNewContact.vue' /* webpackChunkName: "components/modal-business-connection-add-new-contact" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessConnectionEdit = () => import('../../src/components/modals/inventory/business-connection-edit/ModalBusinessConnectionEdit.vue' /* webpackChunkName: "components/modal-business-connection-edit" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessUnit = () => import('../../src/components/modals/inventory/business-unit/ModalBusinessUnit.vue' /* webpackChunkName: "components/modal-business-unit" */).then(c => wrapFunctional(c.default || c))
export const ModalCommodityDetails = () => import('../../src/components/modals/inventory/commodity-details/ModalCommodityDetails.vue' /* webpackChunkName: "components/modal-commodity-details" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirmHarvest = () => import('../../src/components/modals/inventory/confirm-harvest/ModalConfirmHarvest.vue' /* webpackChunkName: "components/modal-confirm-harvest" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirmHarvestProgress = () => import('../../src/components/modals/inventory/confirm-harvest-progress/ModalConfirmHarvestProgress.vue' /* webpackChunkName: "components/modal-confirm-harvest-progress" */).then(c => wrapFunctional(c.default || c))
export const ModalContractBreakdown = () => import('../../src/components/modals/inventory/contract-breakdown/ModalContractBreakdown.vue' /* webpackChunkName: "components/modal-contract-breakdown" */).then(c => wrapFunctional(c.default || c))
export const ModalCsvDownload = () => import('../../src/components/modals/inventory/csv-download/ModalCsvDownload.vue' /* webpackChunkName: "components/modal-csv-download" */).then(c => wrapFunctional(c.default || c))
export const ModalDryingServiceMatrix = () => import('../../src/components/modals/inventory/drying-service-matrix/ModalDryingServiceMatrix.vue' /* webpackChunkName: "components/modal-drying-service-matrix" */).then(c => wrapFunctional(c.default || c))
export const ModalEditStorageContract = () => import('../../src/components/modals/inventory/edit-storage-contract/ModalEditStorageContract.vue' /* webpackChunkName: "components/modal-edit-storage-contract" */).then(c => wrapFunctional(c.default || c))
export const ModalExportMovementRecordsCsv = () => import('../../src/components/modals/inventory/export-movement-records-csv/ModalExportMovementRecordsCsv.vue' /* webpackChunkName: "components/modal-export-movement-records-csv" */).then(c => wrapFunctional(c.default || c))
export const ModalFarmDetails = () => import('../../src/components/modals/inventory/farm-details/ModalFarmDetails.vue' /* webpackChunkName: "components/modal-farm-details" */).then(c => wrapFunctional(c.default || c))
export const ModalFieldSelect = () => import('../../src/components/modals/inventory/field-select/ModalFieldSelect.vue' /* webpackChunkName: "components/modal-field-select" */).then(c => wrapFunctional(c.default || c))
export const ModalInviteSend = () => import('../../src/components/modals/inventory/invite-send/ModalInviteSend.vue' /* webpackChunkName: "components/modal-invite-send" */).then(c => wrapFunctional(c.default || c))
export const ModalLocationGroup = () => import('../../src/components/modals/inventory/location-group/ModalLocationGroup.vue' /* webpackChunkName: "components/modal-location-group" */).then(c => wrapFunctional(c.default || c))
export const ModalMobileMenu = () => import('../../src/components/modals/inventory/mobile-menu/ModalMobileMenu.vue' /* webpackChunkName: "components/modal-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ModalRecordEdit = () => import('../../src/components/modals/inventory/record-edit/ModalRecordEdit.vue' /* webpackChunkName: "components/modal-record-edit" */).then(c => wrapFunctional(c.default || c))
export const ModalSampleRecord = () => import('../../src/components/modals/inventory/sample-record/ModalSampleRecord.vue' /* webpackChunkName: "components/modal-sample-record" */).then(c => wrapFunctional(c.default || c))
export const ModalStorageCommodityEdit = () => import('../../src/components/modals/inventory/storage-commodity-edit/ModalStorageCommodityEdit.vue' /* webpackChunkName: "components/modal-storage-commodity-edit" */).then(c => wrapFunctional(c.default || c))
export const ModalStoreInformation = () => import('../../src/components/modals/inventory/store-information/ModalStoreInformation.vue' /* webpackChunkName: "components/modal-store-information" */).then(c => wrapFunctional(c.default || c))
export const ModalTeamMemberPermissionsEdit = () => import('../../src/components/modals/inventory/team-member-permissions-edit/ModalTeamMemberPermissionsEdit.vue' /* webpackChunkName: "components/modal-team-member-permissions-edit" */).then(c => wrapFunctional(c.default || c))
export const ModalTeamMemberPermissionsNew = () => import('../../src/components/modals/inventory/team-member-permissions-new/ModalTeamMemberPermissionsNew.vue' /* webpackChunkName: "components/modal-team-member-permissions-new" */).then(c => wrapFunctional(c.default || c))
export const ModalViewTimelineHistory = () => import('../../src/components/modals/inventory/view-timeline-history/ModalViewTimelineHistory.vue' /* webpackChunkName: "components/modal-view-timeline-history" */).then(c => wrapFunctional(c.default || c))
export const ModalWeighbridgeTicket = () => import('../../src/components/modals/inventory/weighbridge-ticket/ModalWeighbridgeTicket.vue' /* webpackChunkName: "components/modal-weighbridge-ticket" */).then(c => wrapFunctional(c.default || c))
export const ModalAmendSalesContract = () => import('../../src/components/modals/trading/amend-sales-contract/ModalAmendSalesContract.vue' /* webpackChunkName: "components/modal-amend-sales-contract" */).then(c => wrapFunctional(c.default || c))
export const ModalBidArchive = () => import('../../src/components/modals/trading/bid-archive/ModalBidArchive.vue' /* webpackChunkName: "components/modal-bid-archive" */).then(c => wrapFunctional(c.default || c))
export const ModalBusinessUnitDetails = () => import('../../src/components/modals/trading/business-unit-details/ModalBusinessUnitDetails.vue' /* webpackChunkName: "components/modal-business-unit-details" */).then(c => wrapFunctional(c.default || c))
export const ModalBuyerBidNotes = () => import('../../src/components/modals/trading/buyer-bid-notes/ModalBuyerBidNotes.vue' /* webpackChunkName: "components/modal-buyer-bid-notes" */).then(c => wrapFunctional(c.default || c))
export const ModalBuyerDetails = () => import('../../src/components/modals/trading/buyer-details/ModalBuyerDetails.vue' /* webpackChunkName: "components/modal-buyer-details" */).then(c => wrapFunctional(c.default || c))
export const ModalDefaultSpecificationEdit = () => import('../../src/components/modals/trading/default-specification-edit/ModalDefaultSpecificationEdit.vue' /* webpackChunkName: "components/modal-default-specification-edit" */).then(c => wrapFunctional(c.default || c))
export const ModalEditSettlementAndDelivery = () => import('../../src/components/modals/trading/edit-settlement-and-delivery/ModalEditSettlementAndDelivery.vue' /* webpackChunkName: "components/modal-edit-settlement-and-delivery" */).then(c => wrapFunctional(c.default || c))
export const ModalFixPriceSalesContract = () => import('../../src/components/modals/trading/fix-price-sales-contract/ModalFixPriceSalesContract.vue' /* webpackChunkName: "components/modal-fix-price-sales-contract" */).then(c => wrapFunctional(c.default || c))
export const ModalMarkDeliveryAsPaid = () => import('../../src/components/modals/trading/mark-delivery-as-paid/ModalMarkDeliveryAsPaid.vue' /* webpackChunkName: "components/modal-mark-delivery-as-paid" */).then(c => wrapFunctional(c.default || c))
export const ModalMarketListingAlertBuyers = () => import('../../src/components/modals/trading/market-listing-alert-buyers/ModalMarketListingAlertBuyers.vue' /* webpackChunkName: "components/modal-market-listing-alert-buyers" */).then(c => wrapFunctional(c.default || c))
export const ModalMarketListingSpecification = () => import('../../src/components/modals/trading/market-listing-specification/ModalMarketListingSpecification.vue' /* webpackChunkName: "components/modal-market-listing-specification" */).then(c => wrapFunctional(c.default || c))
export const ModalBidAcceptConfirmation = () => import('../../src/components/modals/trading/modal-bid-accept-confirmation/ModalBidAcceptConfirmation.vue' /* webpackChunkName: "components/modal-bid-accept-confirmation" */).then(c => wrapFunctional(c.default || c))
export const ModalEndListing = () => import('../../src/components/modals/trading/modal-end-listing/ModalEndListing.vue' /* webpackChunkName: "components/modal-end-listing" */).then(c => wrapFunctional(c.default || c))
export const ModalOrganisationDetails = () => import('../../src/components/modals/trading/modal-organisation-details/ModalOrganisationDetails.vue' /* webpackChunkName: "components/modal-organisation-details" */).then(c => wrapFunctional(c.default || c))
export const ModalTradeAccepted = () => import('../../src/components/modals/trading/modal-trade-accepted/ModalTradeAccepted.vue' /* webpackChunkName: "components/modal-trade-accepted" */).then(c => wrapFunctional(c.default || c))
export const ModalPriceBreakdown = () => import('../../src/components/modals/trading/price-breakdown/ModalPriceBreakdown.vue' /* webpackChunkName: "components/modal-price-breakdown" */).then(c => wrapFunctional(c.default || c))
export const ModalRollingContract = () => import('../../src/components/modals/trading/rolling-contract/ModalRollingContract.vue' /* webpackChunkName: "components/modal-rolling-contract" */).then(c => wrapFunctional(c.default || c))
export const ModalTradeRequestNotes = () => import('../../src/components/modals/trading/trade-request-notes/ModalTradeRequestNotes.vue' /* webpackChunkName: "components/modal-trade-request-notes" */).then(c => wrapFunctional(c.default || c))
export const ModalTradingAddOfflineSales = () => import('../../src/components/modals/trading/trading-add-offline-sales/ModalTradingAddOfflineSales.vue' /* webpackChunkName: "components/modal-trading-add-offline-sales" */).then(c => wrapFunctional(c.default || c))
export const ModalTradingEditInventory = () => import('../../src/components/modals/trading/trading-edit-inventory/ModalTradingEditInventory.vue' /* webpackChunkName: "components/modal-trading-edit-inventory" */).then(c => wrapFunctional(c.default || c))
export const ModalVerifiedBuyer = () => import('../../src/components/modals/trading/verified-buyer/ModalVerifiedBuyer.vue' /* webpackChunkName: "components/modal-verified-buyer" */).then(c => wrapFunctional(c.default || c))
export const ModalViewContractDocs = () => import('../../src/components/modals/trading/view-contract-docs/ModalViewContractDocs.vue' /* webpackChunkName: "components/modal-view-contract-docs" */).then(c => wrapFunctional(c.default || c))
export const ModalViewSalesContract = () => import('../../src/components/modals/trading/view-sales-contract/ModalViewSalesContract.vue' /* webpackChunkName: "components/modal-view-sales-contract" */).then(c => wrapFunctional(c.default || c))
export const ModalViewTradingNotes = () => import('../../src/components/modals/trading/view-trading-notes/ModalViewTradingNotes.vue' /* webpackChunkName: "components/modal-view-trading-notes" */).then(c => wrapFunctional(c.default || c))
export const NavMobileFooter = () => import('../../src/components/nav/mobile/footer/NavMobileFooter.vue' /* webpackChunkName: "components/nav-mobile-footer" */).then(c => wrapFunctional(c.default || c))
export const NavMobileMenuItem = () => import('../../src/components/nav/mobile/menu-item/NavMobileMenuItem.vue' /* webpackChunkName: "components/nav-mobile-menu-item" */).then(c => wrapFunctional(c.default || c))
export const NavSidebarItem = () => import('../../src/components/nav/sidebar/item/NavSidebarItem.vue' /* webpackChunkName: "components/nav-sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const AwaitingBids = () => import('../../src/components/trading/market-listing/bids/AwaitingBids.vue' /* webpackChunkName: "components/awaiting-bids" */).then(c => wrapFunctional(c.default || c))
export const MyAcceptedBids = () => import('../../src/components/trading/market-listing/bids/MyAcceptedBids.vue' /* webpackChunkName: "components/my-accepted-bids" */).then(c => wrapFunctional(c.default || c))
export const MyBids = () => import('../../src/components/trading/market-listing/bids/MyBids.vue' /* webpackChunkName: "components/my-bids" */).then(c => wrapFunctional(c.default || c))
export const IframeEmbedModal = () => import('../../src/components/utils/iframe-embed/iframeEmbedModal.vue/IframeEmbedModal.vue' /* webpackChunkName: "components/iframe-embed-modal" */).then(c => wrapFunctional(c.default || c))
export const LoaderOverlay = () => import('../../src/components/utils/loader/LoaderOverlay/LoaderOverlay.vue' /* webpackChunkName: "components/loader-overlay" */).then(c => wrapFunctional(c.default || c))
export const FilterBar = () => import('../../src/components/elements/base/filters/filter-bar/FilterBar.vue' /* webpackChunkName: "components/filter-bar" */).then(c => wrapFunctional(c.default || c))
export const FilterHeader = () => import('../../src/components/elements/base/filters/filter-header/FilterHeader.vue' /* webpackChunkName: "components/filter-header" */).then(c => wrapFunctional(c.default || c))
export const FilterTagBar = () => import('../../src/components/elements/base/filters/filter-tags/FilterTagBar.vue' /* webpackChunkName: "components/filter-tag-bar" */).then(c => wrapFunctional(c.default || c))
export const FilterToggles = () => import('../../src/components/elements/base/filters/filter-toggles/FilterToggles.vue' /* webpackChunkName: "components/filter-toggles" */).then(c => wrapFunctional(c.default || c))
export const IconBadge = () => import('../../src/components/elements/base/icon/icon-badge/IconBadge.vue' /* webpackChunkName: "components/icon-badge" */).then(c => wrapFunctional(c.default || c))
export const MapIcon = () => import('../../src/components/elements/base/icon/map-icon/MapIcon.vue' /* webpackChunkName: "components/map-icon" */).then(c => wrapFunctional(c.default || c))
export const PanelExpanding = () => import('../../src/components/elements/base/panel/expanding/PanelExpanding.vue' /* webpackChunkName: "components/panel-expanding" */).then(c => wrapFunctional(c.default || c))
export const StepsHorizontal = () => import('../../src/components/elements/base/steps/horizontal/StepsHorizontal.vue' /* webpackChunkName: "components/steps-horizontal" */).then(c => wrapFunctional(c.default || c))
export const TablePaginationWrap = () => import('../../src/components/elements/base/table/table-pagination-wrap/TablePaginationWrap.vue' /* webpackChunkName: "components/table-pagination-wrap" */).then(c => wrapFunctional(c.default || c))
export const ManualAddressInput = () => import('../../src/components/elements/form/complex-fields/manual-address-input/ManualAddressInput.vue' /* webpackChunkName: "components/manual-address-input" */).then(c => wrapFunctional(c.default || c))
export const MonthDateRange = () => import('../../src/components/elements/form/complex-fields/month-date-range/MonthDateRange.vue' /* webpackChunkName: "components/month-date-range" */).then(c => wrapFunctional(c.default || c))
export const FieldSelectOrganisation = () => import('../../src/components/elements/form/complex-fields/select-organisation/FieldSelectOrganisation.vue' /* webpackChunkName: "components/field-select-organisation" */).then(c => wrapFunctional(c.default || c))
export const MonthPickerVendor = () => import('../../src/components/elements/form/month-picker/vendor/MonthPickerVendor.vue' /* webpackChunkName: "components/month-picker-vendor" */).then(c => wrapFunctional(c.default || c))
export const RadioList = () => import('../../src/components/elements/form/toggle/radio-list/RadioList.vue' /* webpackChunkName: "components/radio-list" */).then(c => wrapFunctional(c.default || c))
export const MapMarker = () => import('../../src/components/elements/geo/map/marker/MapMarker.vue' /* webpackChunkName: "components/map-marker" */).then(c => wrapFunctional(c.default || c))
export const BuyerStep = () => import('../../src/components/forms/trading/sales-contracts/form-steps/BuyerStep.vue' /* webpackChunkName: "components/buyer-step" */).then(c => wrapFunctional(c.default || c))
export const ContractInformationStep = () => import('../../src/components/forms/trading/sales-contracts/form-steps/ContractInformationStep.vue' /* webpackChunkName: "components/contract-information-step" */).then(c => wrapFunctional(c.default || c))
export const InventoryStep = () => import('../../src/components/forms/trading/sales-contracts/form-steps/InventoryStep.vue' /* webpackChunkName: "components/inventory-step" */).then(c => wrapFunctional(c.default || c))
export const ModalCookies = () => import('../../src/components/modals/global/legal/cookies/ModalCookies.vue' /* webpackChunkName: "components/modal-cookies" */).then(c => wrapFunctional(c.default || c))
export const ModalTermsAndConditions = () => import('../../src/components/modals/global/legal/terms-and-conditions/ModalTermsAndConditions.vue' /* webpackChunkName: "components/modal-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const ModalTermsAndConditionsForm = () => import('../../src/components/modals/global/legal/terms-and-conditions-form/ModalTermsAndConditionsForm.vue' /* webpackChunkName: "components/modal-terms-and-conditions-form" */).then(c => wrapFunctional(c.default || c))
export const ButtonToggle = () => import('../../src/components/elements/base/button/templates/toggle/ButtonToggle.vue' /* webpackChunkName: "components/button-toggle" */).then(c => wrapFunctional(c.default || c))
export const FilterBarSelect = () => import('../../src/components/elements/base/filters/filter-bar/filter-bar-select/FilterBarSelect.vue' /* webpackChunkName: "components/filter-bar-select" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderAvatar = () => import('../../src/components/elements/base/placeholder/templates/avatar/PlaceholderAvatar.vue' /* webpackChunkName: "components/placeholder-avatar" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderLoadList = () => import('../../src/components/elements/base/placeholder/templates/load-list/PlaceholderLoadList.vue' /* webpackChunkName: "components/placeholder-load-list" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderMap = () => import('../../src/components/elements/base/placeholder/templates/map/PlaceholderMap.vue' /* webpackChunkName: "components/placeholder-map" */).then(c => wrapFunctional(c.default || c))
export const FileUploadMini = () => import('../../src/components/elements/form/file-upload/templates/fileUploadMini/FileUploadMini.vue' /* webpackChunkName: "components/file-upload-mini" */).then(c => wrapFunctional(c.default || c))
export const InputAddressLookup = () => import('../../src/components/elements/form/input/templates/address-lookup/InputAddressLookup.vue' /* webpackChunkName: "components/input-address-lookup" */).then(c => wrapFunctional(c.default || c))
export const InputNumericConvertor = () => import('../../src/components/elements/form/input/templates/convertor/InputNumericConvertor.vue' /* webpackChunkName: "components/input-numeric-convertor" */).then(c => wrapFunctional(c.default || c))
export const InputCoordinates = () => import('../../src/components/elements/form/input/templates/coordinates/InputCoordinates.vue' /* webpackChunkName: "components/input-coordinates" */).then(c => wrapFunctional(c.default || c))
export const InputCurrency = () => import('../../src/components/elements/form/input/templates/currency/InputCurrency.vue' /* webpackChunkName: "components/input-currency" */).then(c => wrapFunctional(c.default || c))
export const InputPassword = () => import('../../src/components/elements/form/input/templates/password/InputPassword.vue' /* webpackChunkName: "components/input-password" */).then(c => wrapFunctional(c.default || c))
export const InputSearch = () => import('../../src/components/elements/form/input/templates/search/InputSearch.vue' /* webpackChunkName: "components/input-search" */).then(c => wrapFunctional(c.default || c))
export const InputTelephone = () => import('../../src/components/elements/form/input/templates/telephone/InputTelephone.vue' /* webpackChunkName: "components/input-telephone" */).then(c => wrapFunctional(c.default || c))
export const InputWhat3Words = () => import('../../src/components/elements/form/input/templates/w3w-lookup/InputWhat3Words.vue' /* webpackChunkName: "components/input-what3-words" */).then(c => wrapFunctional(c.default || c))
export const InputWeight = () => import('../../src/components/elements/form/input/templates/weight/InputWeight.vue' /* webpackChunkName: "components/input-weight" */).then(c => wrapFunctional(c.default || c))
export const InputSearchAutocomplete = () => import('../../src/components/elements/form/input/templates/search/autocomplete/InputSearchAutocomplete.vue' /* webpackChunkName: "components/input-search-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const InputSearchSelector = () => import('../../src/components/elements/form/input/templates/search/autocomplete/templates/InputSearchSelector/InputSearchSelector.vue' /* webpackChunkName: "components/input-search-selector" */).then(c => wrapFunctional(c.default || c))
export const InputSearchSelectorTags = () => import('../../src/components/elements/form/input/templates/search/autocomplete/templates/InputSearchSelector/wrappers/InputSearchSelectorTags.vue' /* webpackChunkName: "components/input-search-selector-tags" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
